import { useContext, useState } from "react";
import Template from "./Config/Template";
import { Layout } from "@nimbus-ds/patterns";
import {
  Accordion,
  Box,
  Button,
  Card,
  Text,
  Spinner,
} from "@nimbus-ds/components";
import Content from "./Config/Content/Content";
import Timer from "./Config/Timer/Timer";
import Style from "./Config/Style/Style";
import Targeting from "./Config/Targeting/Targeting";
import { BarContext } from "../../context/BarContext";
import "./style.css"; // Asegúrate de que esta ruta sea correcta
import Bar from "./Preview/Bar";
import { useNavigate } from "react-router-dom";
import il8next from "../../I18n";

export default function CreateOrUpdateBar({
  bar = null,
  onAddBar,
  errors,
  setErrors,
}) {
  const { mobilePreview, setMobilePreview, content, timer, style, targeting } =
    useContext(BarContext);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // fix para hacer sticky el bar preview
  const generateStyles = () => {
    const el = document.querySelector("#bar-preview");
    if (!el) return;
    const parentElement = el?.parentElement;
    if (mobilePreview) {
      // add fixed position sticky, top 0, and index 10
      parentElement.style.position = "sticky";
      parentElement.style.top = 0;
      parentElement.style.zIndex = 10;
      return {
        position: "sticky",
        top: 0,
        zIndex: 10,
      };
    } else {
      return {
        position: "sticky",
        top: 0,
        zIndex: 10,
      };
    }
  };

  const configSection = (
    <Layout.Section key="config">
      <Box padding="2">
        <Card>
          <Card.Header>
            <Text fontSize="base">{il8next.t("Config", { ns: "bars" })}</Text>
          </Card.Header>
          <Accordion>
            <Content errors={errors} />
            <Timer errors={errors} />
            <Style errors={errors} />
            <Targeting errors={errors} />
          </Accordion>
        </Card>
      </Box>
    </Layout.Section>
  );
  const previewSection = (
    <Layout.Section key="preview">
      <div style={generateStyles()} id="bar-preview">
        <Box padding="2">
          <Card>
            <Card.Header>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text fontSize="base">
                  {!mobilePreview
                    ? il8next.t("Mobile preview", { ns: "bars" })
                    : il8next.t("Desktop preview", { ns: "bars" })}
                </Text>
                <div className="btn-preview-wrapper">
                  <Button onClick={() => setMobilePreview(!mobilePreview)}>
                    {!mobilePreview
                      ? il8next.t("Switch to desktop preview", { ns: "bars" })
                      : il8next.t("Switch to mobile preview", { ns: "bars" })}
                  </Button>
                </div>
              </div>
            </Card.Header>
            <Bar />
          </Card>
        </Box>
      </div>
    </Layout.Section>
  );
  const renderSections = () => {
    return mobilePreview
      ? [previewSection, configSection]
      : [configSection, previewSection];
  };

  return (
    <div className="container">
      <Box padding="2">
        <Template />
      </Box>
      <div className="content-wrapper">
        <Layout
          margin={"spacing-3"}
          columns={mobilePreview ? "1 - symmetric" : "2 - asymmetric"}
        >
          {renderSections()}
        </Layout>
      </div>
      <Box padding="2">
        <Card>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {il8next.t("Cancel")}
            </Button>
            <span
              style={{
                marginLeft: "1rem",
              }}
            >
              <Button
                appearance="primary"
                onClick={async () => {
                  try {
                    // wait 5 secds
                    setErrors(null);
                    setLoading(true);
                    await onAddBar({
                      barId: bar ? bar.id : null,
                      content,
                      timer,
                      style,
                      targeting,
                    });
                    setLoading(false);
                  } catch (error) {
                    console.error(error);
                    setLoading(false);
                  }
                }}
                disabled={loading}
              >
                {loading && <Spinner color="currentColor" size="small" />}
                {il8next.t("Save")}
              </Button>
            </span>
          </div>
        </Card>
      </Box>
    </div>
  );
}
