import { connect, iAmReady } from "@tiendanube/nexo";
import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import nexo from "./nexo";
import NexoSyncRoute from "./NexoSyncRoute";
import MainPage from "./pages/MainPage";
import "@nimbus-ds/styles/dist/index.css";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { AppShell, HelpLink } from "@nimbus-ds/patterns";
import { Box, Button, Icon } from "@nimbus-ds/components";
import { ChevronLeftIcon, GiftBoxIcon, UserIcon } from "@nimbus-ds/icons";
import NewBarPage from "./pages/Bar/NewBarPage";
import { useLocation } from "react-router-dom";
import EditBarPage from "./pages/EditBarPage";
import NotFoundPage from "./pages/NotFoundPage";
import { ToastProvider } from "@nimbus-ds/components";
import { ACTION_STORE_INFO } from "@tiendanube/nexo";
import DarkModeProvider from "./utils/DarkModeProvider";
import { ExternalLinkIcon } from "@nimbus-ds/icons";
import il8next from "./I18n";
import LanguageSwitcher from "./components/ui/LanguageSwitcher";

const backButton = (
  <Link to="..">
    <Button>
      <Icon source={<ChevronLeftIcon />} />
      {il8next.t("Back")}
    </Button>
  </Link>
);

const buttonStack = (
  <>
    <Link to="/">
      <Button>
        {/* <Icon source={<GiftBoxIcon />} /> */}
        {il8next.t("Home")}
      </Button>
    </Link>
    <LanguageSwitcher />
    {/* <Button>
      <Icon source={<UserIcon />} />
      My account
    </Button> */}
    <Link to="https://nubysolutions.com/docs/intro" target="_blank">
      <Button>
        <HelpLink>
          {il8next.t("Help")}
          <Icon source={<ExternalLinkIcon />} color="currentColor" />
        </HelpLink>
      </Button>
    </Link>
  </>
);

function Layout() {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <AppShell>
      <AppShell.Header
        leftSlot={currentPath !== "/" ? backButton : null}
        rightSlot={buttonStack}
      />

      <Outlet />
    </AppShell>
  );
}

function App() {
  const [isConnect, setIsConnect] = useState(false);
  useEffect(() => {
    connect(nexo).then(async () => {
      setIsConnect(true);
      iAmReady(nexo);
    });
  }, []);

  useEffect(() => {
    const obtainUserInfo = nexo.suscribe(ACTION_STORE_INFO, (res) => {
      console.log("obtainUserInfo: ", res);
    });
    return () => obtainUserInfo();
  }, []);

  if (!isConnect) return null;
  return (
    <BrowserRouter>
      <DarkModeProvider>
        <ToastProvider>
          <NexoSyncRoute>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<MainPage />} />
                <Route path="/new" element={<NewBarPage />} />
                <Route path="/not-found" element={<NotFoundPage />} />
                <Route path="/bar/:barId" element={<EditBarPage />} />
              </Route>
            </Routes>
          </NexoSyncRoute>
        </ToastProvider>
      </DarkModeProvider>
    </BrowserRouter>
  );
}

export default App;
