import {
  Accordion,
  Box,
  Card,
  Checkbox,
  Label,
  Radio,
  Select,
  Text,
} from "@nimbus-ds/components";
import { FormField, Layout } from "@nimbus-ds/patterns";
import { useContext, useEffect, useState } from "react";
import { BarContext } from "../../../../context/BarContext";
import { ErrorIcon } from "../../../ui/Icons";
import il8next from "../../../../I18n";

export default function Content({ errors }) {
  const { content, setContent } = useContext(BarContext);

  const [localErrors, setLocalErrors] = useState({});
  // Update local errors when errors change
  useEffect(() => {
    if (errors && errors.error) {
      const newErrors = errors.error.reduce((acc, err) => {
        if (err.path[0] === "content") {
          acc[err.path[1]] = err.message;
        }
        return acc;
      }, {});
      setLocalErrors(newErrors);
    } else {
      setLocalErrors({});
    }
  }, [errors]);

  return (
    <Accordion.Item index="0">
      <Accordion.Header
        icon={
          Object.keys(localErrors).length > 0 ? (
            <ErrorIcon
              style={{
                color: "red",
              }}
            />
          ) : null
        }
        title={il8next.t("content.Content configuration", {
          ns: "bars",
        })}
        subtitle={il8next.t("content.Customize the content of your bar", {
          ns: "bars",
        })}
      />
      <Accordion.Body>
        <Box borderWidth="1" padding="2" width="100%">
          <Card>
            <Box padding="1">
              <FormField.Input
                appearance={localErrors.barName ? "danger" : "neutral"}
                showHelpText={true}
                helpText={
                  localErrors.barName
                    ? localErrors.barName
                    : il8next.t(
                        "content.For your internal reference, only you will see this",
                        {
                          ns: "bars",
                        }
                      )
                }
                label={il8next.t("content.Bar name", {
                  ns: "bars",
                })}
                id="barName"
                placeholder=""
                onChange={(e) =>
                  setContent({ ...content, barName: e.target.value })
                }
                value={content.barName}
              />
            </Box>
            <Box padding="1">
              <Layout margin={"spacing-3"} columns="2 - symmetric">
                <Layout.Section>
                  <FormField.Input
                    appearance={
                      localErrors.contentMessageBeforeTimer
                        ? "danger"
                        : "neutral"
                    }
                    showHelpText={localErrors.contentMessageBeforeTimer}
                    helpText={
                      localErrors.contentMessageBeforeTimer &&
                      localErrors.contentMessageBeforeTimer
                    }
                    label={il8next.t("content.Message before timer", {
                      ns: "bars",
                    })}
                    id="contentMessageBeforeTimer"
                    onChange={(e) =>
                      setContent({
                        ...content,
                        contentMessageBeforeTimer: e.target.value,
                      })
                    }
                    value={content.contentMessageBeforeTimer}
                  />
                </Layout.Section>
                <Layout.Section>
                  <FormField.Input
                    appearance={
                      localErrors.contentMessageAfterTimer
                        ? "danger"
                        : "neutral"
                    }
                    showHelpText={localErrors.contentMessageAfterTimer}
                    helpText={
                      localErrors.contentMessageAfterTimer &&
                      localErrors.contentMessageAfterTimer
                    }
                    label={il8next.t("content.Message after timer", {
                      ns: "bars",
                    })}
                    id="contentMessageAfterTimer"
                    onChange={(e) =>
                      setContent({
                        ...content,
                        contentMessageAfterTimer: e.target.value,
                      })
                    }
                    value={content.contentMessageAfterTimer}
                  />
                </Layout.Section>
              </Layout>
            </Box>

            <Box padding="1">
              <FormField.Select
                appearance="neutral"
                id="contentCallToAction"
                name="contentCallToAction"
                label={il8next.t("content.Call to action", {
                  ns: "bars",
                })}
                onChange={(e) =>
                  setContent({
                    ...content,
                    contentCallToAction: e.target.value,
                  })
                }
                defaultValue={content.contentCallToAction}
              >
                <Select.Option
                  label={il8next.t("content.None", {
                    ns: "bars",
                  })}
                  value="none"
                />
                <Select.Option
                  label={il8next.t("content.Add a button", {
                    ns: "bars",
                  })}
                  value="addButton"
                />
                <Select.Option
                  label={il8next.t("content.Make the entire bar clickable", {
                    ns: "bars",
                  })}
                  value="entireBarClickable"
                />
              </FormField.Select>
            </Box>
            {content.contentCallToAction === "addButton" && (
              <>
                <Box padding="1">
                  <FormField.Input
                    appearance={
                      localErrors.contentButtonText ? "danger" : "neutral"
                    }
                    showHelpText={localErrors.contentButtonText}
                    helpText={
                      localErrors.contentButtonText &&
                      localErrors.contentButtonText
                    }
                    label={il8next.t("content.Button text", {
                      ns: "bars",
                    })}
                    id="contentButtonText"
                    onChange={(e) => {
                      setContent({
                        ...content,
                        contentButtonText: e.target.value,
                      });
                    }}
                    value={content.contentButtonText}
                  />
                </Box>
                <Box padding="1">
                  <FormField.Input
                    appearance={
                      localErrors.contentButtonLinkUrl ? "danger" : "neutral"
                    }
                    showHelpText={localErrors.contentButtonLinkUrl}
                    helpText={
                      localErrors.contentButtonLinkUrl &&
                      localErrors.contentButtonLinkUrl
                    }
                    label={il8next.t("content.Link url", {
                      ns: "bars",
                    })}
                    id="contentButtonLinkUrl"
                    onChange={(e) => {
                      setContent({
                        ...content,
                        contentButtonLinkUrl: e.target.value,
                      });
                    }}
                    value={content.contentButtonLinkUrl}
                  />
                </Box>
                <Box padding="1">
                  <Checkbox
                    name="contentButtonOpenNewTab"
                    label={il8next.t(
                      "content.Open link in new tab when clicked",
                      {
                        ns: "bars",
                      }
                    )}
                    onChange={(e) =>
                      setContent({
                        ...content,
                        contentButtonOpenNewTab: e.target.checked,
                      })
                    }
                    value={content.contentButtonOpenNewTab}
                  />
                </Box>
              </>
            )}
            {content.contentCallToAction === "entireBarClickable" && (
              <>
                <Box padding="1">
                  <FormField.Input
                    label={il8next.t("content.Link url", {
                      ns: "bars",
                    })}
                    id="contentBarLinkUrl"
                    onChange={(e) => {
                      setContent({
                        ...content,
                        contentBarLinkUrl: e.target.value,
                      });
                    }}
                    value={content.contentBarLinkUrl}
                  />
                </Box>
                <Box padding="1">
                  <Checkbox
                    name="contentBarOpenNewTab"
                    label={il8next.t(
                      "content.Open link in new tab when clicked",
                      {
                        ns: "bars",
                      }
                    )}
                    onChange={(e) =>
                      setContent({
                        ...content,
                        contentBarOpenNewTab: e.target.checked,
                      })
                    }
                    value={content.contentBarOpenNewTab}
                  />
                </Box>
              </>
            )}

            <Box padding="1">
              <FormField.Select
                appearance="neutral"
                id="contentIncludeCloseButton"
                name="contentIncludeCloseButton"
                label={il8next.t("content.Include Close Button", {
                  ns: "bars",
                })}
                helpText={il8next.t(
                  "content.Place an 'x' button on the bar so that users can close it manually",
                  {
                    ns: "bars",
                  }
                )}
                showHelpText={true}
                onChange={(e) =>
                  setContent({
                    ...content,
                    contentIncludeCloseButton:
                      e.target.value === "true" ? true : false,
                  })
                }
                defaultValue={content.contentIncludeCloseButton}
              >
                <Select.Option label={il8next.t("Yes")} value={true} />
                <Select.Option label={il8next.t("No")} value={false} />
              </FormField.Select>
            </Box>
            <Box padding="1">
              <Label htmlFor="contentDisplayPosition">
                {il8next.t("content.Select a Display Position", {
                  ns: "bars",
                })}
              </Label>
              <Radio
                id={1}
                name="contentDisplayPosition"
                label={il8next.t(
                  "content.Top - pushes down the rest of the page",
                  {
                    ns: "bars",
                  }
                )}
                onChange={(e) =>
                  setContent({
                    ...content,
                    contentDisplayPosition: 1,
                  })
                }
                checked={content.contentDisplayPosition === 1}
              />
              <Radio
                id={2}
                name="contentDisplayPosition"
                label={il8next.t(
                  "content.Top - pushes down the rest of the page (visible while scrolling)",
                  {
                    ns: "bars",
                  }
                )}
                onChange={(e) =>
                  setContent({
                    ...content,
                    contentDisplayPosition: 2,
                  })
                }
                checked={content.contentDisplayPosition === 2}
              />
              <Radio
                id={3}
                name="contentDisplayPosition"
                label={il8next.t("content.Top - overlaps top of the page", {
                  ns: "bars",
                })}
                onChange={(e) =>
                  setContent({
                    ...content,
                    contentDisplayPosition: 3,
                  })
                }
                checked={content.contentDisplayPosition === 3}
              />
              <Radio
                id={4}
                name="contentDisplayPosition"
                label={il8next.t(
                  "content.Top - overlaps top of the page (visible while scrolling)",
                  {
                    ns: "bars",
                  }
                )}
                onChange={(e) =>
                  setContent({
                    ...content,
                    contentDisplayPosition: 4,
                  })
                }
                checked={content.contentDisplayPosition === 4}
              />
              <Radio
                id={5}
                name="contentDisplayPosition"
                label={il8next.t(
                  "content.Bottom - overlaps bottom of the page (visible while scrolling)",
                  {
                    ns: "bars",
                  }
                )}
                onChange={(e) =>
                  setContent({
                    ...content,
                    contentDisplayPosition: 5,
                  })
                }
                checked={content.contentDisplayPosition === 5}
              />
            </Box>
          </Card>
        </Box>
      </Accordion.Body>
    </Accordion.Item>
  );
}
