import axiosApi from "axios";
import { getSessionToken } from "@tiendanube/nexo";
import nexo from "../nexo";

const axios = axiosApi.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.request.use(async (request) => {
  const token = await getSessionToken(nexo);
  const bearerToken = `Bearer ${token}`;
  request.headers = { ...request.headers, Authorization: bearerToken };
  return request;
});

export default axios;
