import React, { useState, useEffect, useContext } from "react";
import FlipDigit from "./FlipDigit";
import { BarContext } from "../../../../../context/BarContext";
import "./style.css";
const Countdown = ({ targetDate }) => {
  const { style } = useContext(BarContext);

  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatDigits = (number) => number.toString().padStart(2, "0");

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--flip-background-color",
      style.timerBackgroundColor
    );
    document.documentElement.style.setProperty(
      "--timer-digit-color",
      style.timerDigitColor
    );
  }, [style]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        {formatDigits(timeLeft.days)
          .split("")
          .map((digit, index) => (
            <FlipDigit key={`days-${index}`} digit={digit} />
          ))}
        :
        {formatDigits(timeLeft.hours)
          .split("")
          .map((digit, index) => (
            <FlipDigit key={`hours-${index}`} digit={digit} />
          ))}
        :
        {formatDigits(timeLeft.minutes)
          .split("")
          .map((digit, index) => (
            <FlipDigit key={`minutes-${index}`} digit={digit} />
          ))}
        :
        {formatDigits(timeLeft.seconds)
          .split("")
          .map((digit, index) => (
            <FlipDigit key={`seconds-${index}`} digit={digit} />
          ))}
      </div>
    </div>
  );
};

export default Countdown;
