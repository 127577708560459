import {
  Accordion,
  Box,
  Button,
  Card,
  Checkbox,
  FileUploader,
  Input,
  Label,
  Radio,
  Text,
  Title,
} from "@nimbus-ds/components";
import { FormField, Layout } from "@nimbus-ds/patterns";
import { useContext, useEffect, useRef, useState } from "react";
import ColorPickerInput from "../../../ui/ColorPicker";
import "./style.css";
import { BarContext } from "../../../../context/BarContext";
import { ErrorIcon } from "../../../ui/Icons";
import il8next from "../../../../I18n";

export default function Style({ errors }) {
  const { style, setStyle } = useContext(BarContext);

  console.log({ style });

  const [localErrors, setLocalErrors] = useState({});
  const [customPatternImage, setCustomPatternImage] = useState(null);
  const [customFittedImage, setCustomFittedImage] = useState(null);
  // Update local errors when errors change
  useEffect(() => {
    if (errors && errors.error) {
      const newErrors = errors?.error?.reduce((acc, err) => {
        if (err.path[0] === "style") {
          acc[err.path[1]] = err.message;
        }
        return acc;
      }, {});
      setLocalErrors(newErrors);
    } else {
      setLocalErrors({});
    }
  }, [errors]);

  const handlePatternCustomImageUpload = (e) => {
    // Get the file object from the file input event
    const file = e.target.files[0];
    if (file) {
      // Create a URL for the file
      setCustomPatternImage(URL.createObjectURL(file));
      // Update the state with the file object and URL
      setStyle({
        ...style,
        backgroundImagePatternImage: {
          type: "custom",
          file: file,
        },
      });
    }
    // Reset the file input
    e.target.value = null;
  };
  const handleFittedCustomImageUpload = (e) => {
    // Get the file object from the file input event
    const file = e.target.files[0];
    if (file) {
      // Create a URL for the file
      setCustomFittedImage(URL.createObjectURL(file));
      // Update the state with the file object and URL
      setStyle({
        ...style,
        backgroundFittedImage: {
          ...style.backgroundFittedImage,
          type: "custom",
          file: file,
        },
      });
    }
    // Reset the file input
    e.target.value = null;
  };

  return (
    <Accordion.Item index="2">
      <Accordion.Header
        title={il8next.t("style.Style configuration", { ns: "bars" })}
        icon={
          Object.keys(localErrors).length > 0 ? (
            <ErrorIcon
              style={{
                color: "red",
              }}
            />
          ) : null
        }
        subtitle={il8next.t("style.Customize the style of your bar", {
          ns: "bars",
        })}
      />
      <Accordion.Body>
        <Box borderWidth="1" padding="2" width="100%">
          <Card>
            <Box padding="1">
              <Label htmlFor="styleTimer">
                {il8next.t("style.Timer Style", { ns: "bars" })}
              </Label>
              <Box display={"flex"} flexWrap={"wrap"}>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.styleTimer === "flipClock" ? "primary" : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, styleTimer: "flipClock" })
                    }
                  >
                    {il8next.t("style.Flip clock", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.styleTimer === "plainNumbers"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, styleTimer: "plainNumbers" })
                    }
                  >
                    {il8next.t("style.Plain numbers", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.styleTimer === "tiles" &&
                      style.tiles.type === "square"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({
                        ...style,
                        styleTimer: "tiles",
                        tiles: { ...style.tiles, type: "square" },
                      })
                    }
                  >
                    {il8next.t("style.Square tiles", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.styleTimer === "tiles" &&
                      style.tiles.type === "circle"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({
                        ...style,
                        styleTimer: "tiles",
                        tiles: { ...style.tiles, type: "circle" },
                      })
                    }
                  >
                    {il8next.t("style.Circle tiles", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.styleTimer === "none" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, styleTimer: "none" })}
                  >
                    {il8next.t("style.None - hide timer", { ns: "bars" })}
                  </Button>
                </Box>
              </Box>
            </Box>

            {style.styleTimer === "flipClock" && (
              <Box padding="1">
                <Layout columns="2 - symmetric">
                  <Layout.Section>
                    <Label htmlFor="styleFlipClockBackgroundColor">
                      {il8next.t("style.Background color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.backgroundColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          backgroundColor: color,
                        })
                      }
                      hasError={localErrors.backgroundColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="styleFlipClockTextColor">
                      {il8next.t("style.Text color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.textColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          textColor: color,
                        })
                      }
                      hasError={localErrors.textColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="styleFlipClockTimerBackgroundColor">
                      {il8next.t("style.Timer background color", {
                        ns: "bars",
                      })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.timerBackgroundColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          timerBackgroundColor: color,
                        })
                      }
                      hasError={localErrors.timerBackgroundColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="styleFlipClockTimerDigitColor">
                      {il8next.t("style.Timer digit color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.timerDigitColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          timerDigitColor: color,
                        })
                      }
                      hasError={localErrors.timerDigitColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="styleFlipClockButtonBackgroundColor">
                      {il8next.t("style.Button background color", {
                        ns: "bars",
                      })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.buttonBackgroundColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          buttonBackgroundColor: color,
                        })
                      }
                      hasError={localErrors.buttonBackgroundColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="styleFlipClockButtonTextColor">
                      {il8next.t("style.Button text color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.buttonTextColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          buttonTextColor: color,
                        })
                      }
                      hasError={localErrors.buttonTextColor}
                    />
                  </Layout.Section>
                </Layout>
              </Box>
            )}

            {style.styleTimer === "plainNumbers" && (
              <Box padding="1">
                <Layout columns="2 - symmetric">
                  <Layout.Section>
                    <Label htmlFor="stylePlainNumbersBackgroundColor">
                      {il8next.t("style.Background color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.backgroundColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          backgroundColor: color,
                        })
                      }
                      hasError={localErrors.backgroundColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="stylePlainNumbersTextColor">
                      {il8next.t("style.Text color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.textColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          textColor: color,
                        })
                      }
                      hasError={localErrors.textColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="stylePlainNumbersTimerDigitColor">
                      {il8next.t("style.Timer digit color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.timerDigitColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          timerDigitColor: color,
                        })
                      }
                      hasError={localErrors.timerDigitColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="stylePlainNumbersColonSeparatorColor">
                      {il8next.t("style.Colon separator color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.colonSeparatorColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          colonSeparatorColor: color,
                        })
                      }
                      hasError={localErrors.colonSeparatorColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="stylePlainNumbersButtonBackgroundColor">
                      {il8next.t("style.Button background color", {
                        ns: "bars",
                      })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.buttonBackgroundColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          buttonBackgroundColor: color,
                        })
                      }
                      hasError={localErrors.buttonBackgroundColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="stylePlainNumbersButtonTextColor">
                      {il8next.t("style.Button text color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.buttonTextColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          buttonTextColor: color,
                        })
                      }
                      hasError={localErrors.buttonTextColor}
                    />
                  </Layout.Section>
                </Layout>
              </Box>
            )}

            {style.styleTimer === "tiles" && (
              <Box padding="1">
                <Layout columns="2 - symmetric">
                  <Layout.Section>
                    <Label htmlFor="styleTilesBackgroundColor">
                      {il8next.t("style.Background color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.backgroundColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          backgroundColor: color,
                        })
                      }
                      hasError={localErrors.backgroundColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="styleTilesTextColor">
                      {il8next.t("style.Text color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.textColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          textColor: color,
                        })
                      }
                      hasError={localErrors.textColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="styleTilesTimerBackgroundColor">
                      {il8next.t("style.Timer background color", {
                        ns: "bars",
                      })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.timerBackgroundColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          timerBackgroundColor: color,
                        })
                      }
                      hasError={localErrors.timerBackgroundColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Checkbox
                      name="styleTilesTimerGradient"
                      label={il8next.t("style.Timer Background Gradient", {
                        ns: "bars",
                      })}
                      checked={style.tiles.timerBackgroundGradient}
                      onChange={() =>
                        setStyle({
                          ...style,
                          tiles: {
                            ...style.tiles,
                            timerBackgroundGradient:
                              !style.tiles.timerBackgroundGradient,
                          },
                        })
                      }
                      hasError={localErrors.timerBackgroundGradient}
                    />
                    <ColorPickerInput
                      disabled={!style.tiles.timerBackgroundGradient}
                      defaultColor={style.tiles.timerBackgroundGradientColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          tiles: {
                            ...style.tiles,
                            timerBackgroundGradientColor: color,
                          },
                        })
                      }
                      hasError={localErrors.timerBackgroundGradientColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="styleTilesTimerDigitColor">
                      {il8next.t("style.Timer digit color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.timerDigitColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          timerDigitColor: color,
                        })
                      }
                      hasError={localErrors.timerDigitColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="styleTilesColonSeparatorColor">
                      {il8next.t("style.Colon separator color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.colonSeparatorColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          colonSeparatorColor: color,
                        })
                      }
                      hasError={localErrors.colonSeparatorColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="styleTilesButtonBackgroundColor">
                      {il8next.t("style.Button background color", {
                        ns: "bars",
                      })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.buttonBackgroundColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          buttonBackgroundColor: color,
                        })
                      }
                      hasError={localErrors.buttonBackgroundColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="styleTilesButtonTextColor">
                      {il8next.t("style.Timer button text color", {
                        ns: "bars",
                      })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.buttonTextColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          ...style.tiles,
                          buttonTextColor: color,
                        })
                      }
                      hasError={localErrors.buttonTextColor}
                    />
                  </Layout.Section>
                </Layout>
              </Box>
            )}

            {style.styleTimer === "none" && (
              <Box padding="1">
                <Layout columns="2 - symmetric">
                  <Layout.Section>
                    <Label htmlFor="styleNoneBackgroundColor">
                      {il8next.t("style.Background color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.backgroundColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          backgroundColor: color,
                        })
                      }
                      hasError={localErrors.backgroundColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="styleNoneTextColor">
                      {il8next.t("style.Text color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.textColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          textColor: color,
                        })
                      }
                      hasError={localErrors.textColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="styleNoneButtonBackgroundColor">
                      {il8next.t("style.Button background color", {
                        ns: "bars",
                      })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.buttonBackgroundColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          buttonBackgroundColor: color,
                        })
                      }
                      hasError={localErrors.buttonBackgroundColor}
                    />
                  </Layout.Section>
                  <Layout.Section>
                    <Label htmlFor="styleNoneButtonTextColor">
                      {il8next.t("style.Button text color", { ns: "bars" })}
                    </Label>
                    <ColorPickerInput
                      defaultColor={style.buttonTextColor}
                      onColorChange={(color) =>
                        setStyle({
                          ...style,
                          buttonTextColor: color,
                        })
                      }
                      hasError={localErrors.buttonTextColor}
                    />
                  </Layout.Section>
                </Layout>
              </Box>
            )}

            <Box padding="1">
              <Label>
                {il8next.t("style.Background Color Opacity", {
                  ns: "bars",
                })}
                : {style.backgroundColorOpacity}
              </Label>
              <input
                type="range"
                id="styleBackgroundColorOpacity"
                name="styleBackgroundColorOpacity"
                min="0"
                max="1"
                step="0.1"
                value={style.backgroundColorOpacity}
                style={{
                  width: "100%",
                }}
                onChange={(e) =>
                  setStyle({
                    ...style,
                    backgroundColorOpacity: e.target.value,
                  })
                }
              />
              <Text>
                {il8next.t(
                  "style.The range is from 0 to 1. Set to 0 for fully transparent, and to 1 for solid",
                  { ns: "bars" }
                )}
              </Text>
            </Box>

            <Box padding="1">
              <Label htmlFor="styleBackgroundImage">
                {il8next.t("style.Background Image Style", { ns: "bars" })}
              </Label>
              <Box display={"flex"} flexWrap={"wrap"}>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.backgroundImageStyle === "noImage"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({
                        ...style,
                        backgroundImageStyle: "noImage",
                        backgroundColorOpacity: 1,
                      })
                    }
                  >
                    {il8next.t("style.No Image", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.backgroundImageStyle === "colorGradient"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({
                        ...style,
                        backgroundImageStyle: "colorGradient",
                      })
                    }
                  >
                    {il8next.t("style.Color Gradient", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.backgroundImageStyle === "patternImage"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({
                        ...style,
                        backgroundImageStyle: "patternImage",
                      })
                    }
                  >
                    {il8next.t("style.Pattern Image", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.backgroundImageStyle === "fittedImage"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({
                        ...style,
                        backgroundImageStyle: "fittedImage",
                      })
                    }
                  >
                    {il8next.t("style.Fitted Image", { ns: "bars" })}
                  </Button>
                </Box>
              </Box>
            </Box>

            {style.backgroundImageStyle === "colorGradient" && (
              <Box padding="1-5">
                <Box padding="1">
                  <Label htmlFor="styleBackgroundImageColorGradient">
                    {il8next.t("style.Select a gradient color image", {
                      ns: "bars",
                    })}
                  </Label>
                  <Layout columns="2 - symmetric">
                    <Layout.Section>
                      <div
                        className="colorGradient orangeJuice"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundColorOpacity: 0,
                            backgroundImageColorGradient: {
                              type: "orangeJuice",
                              color1: "#FF6162",
                              color2: "#FF9766",
                              color3: null,
                              gradientDegree: 90,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Orange Juice", { ns: "bars" })}
                        </Title>
                        <Text>
                          {il8next.t("style.2 colors from left to right", {
                            ns: "bars",
                          })}
                        </Text>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="colorGradient azurePop"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundColorOpacity: 0,
                            backgroundImageColorGradient: {
                              type: "azurePop",
                              color1: "#ef32d9",
                              color2: "#89fffd",
                              color3: null,
                              gradientDegree: 90,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Azure Pop", { ns: "bars" })}
                        </Title>
                        <Text>
                          {il8next.t("style.2 colors from left to right", {
                            ns: "bars",
                          })}
                        </Text>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="colorGradient limeade"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundColorOpacity: 0,
                            backgroundImageColorGradient: {
                              type: "limeade",
                              color1: "#A1FFCE",
                              color2: "#FAFFD1",
                              color3: null,
                              gradientDegree: 90,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Limeade", { ns: "bars" })}
                        </Title>
                        <Text>
                          {il8next.t("style.2 colors from left to right", {
                            ns: "bars",
                          })}
                        </Text>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="colorGradient sunRaise"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundColorOpacity: 0,
                            backgroundImageColorGradient: {
                              type: "sunRaise",
                              color1: "#FF6162",
                              color2: "#FF9766",
                              color3: null,
                              gradientDegree: 0,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Sun Raise", { ns: "bars" })}
                        </Title>
                        <Text>
                          {il8next.t(
                            "style.2 colors from bottom to top (0 degree)",
                            {
                              ns: "bars",
                            }
                          )}
                        </Text>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="colorGradient tropicalSea"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundColorOpacity: 0,
                            backgroundImageColorGradient: {
                              type: "tropicalSea",
                              color1: "#46CDCF",
                              color2: "#bfffec",
                              color3: null,
                              gradientDegree: 45,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Tropical Sea", { ns: "bars" })}
                        </Title>
                        <Text>
                          {il8next.t(
                            "style.2 colors from bottom left to top right (45 degree)",
                            {
                              ns: "bars",
                            }
                          )}
                        </Text>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="colorGradient mauve"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundColorOpacity: 0,
                            backgroundImageColorGradient: {
                              type: "mauve",
                              color1: "#42275a",
                              color2: "#734b6d",
                              color3: null,
                              gradientDegree: 180,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Mauve", { ns: "bars" })}
                        </Title>
                        <Text>
                          {il8next.t(
                            "style.2 colors from top to bottom (180 degree)",
                            {
                              ns: "bars",
                            }
                          )}
                        </Text>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="colorGradient dawn"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundColorOpacity: 0,
                            backgroundImageColorGradient: {
                              type: "dawn",
                              color1: "#b162df",
                              color2: "#ffc757",
                              color3: "#f0585d",
                              gradientDegree: 45,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Dawn", { ns: "bars" })}
                        </Title>
                        <Text>
                          {il8next.t(
                            "style.3 colors from bottom left to top right (45 degree)",
                            {
                              ns: "bars",
                            }
                          )}
                        </Text>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="colorGradient river"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundColorOpacity: 0,
                            backgroundImageColorGradient: {
                              type: "river",
                              color1: "#a97aff",
                              color2: "#618cfe",
                              color3: "#1fe9ec",
                              gradientDegree: 45,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.River", { ns: "bars" })}
                        </Title>
                        <Text>
                          {il8next.t(
                            "style.3 colors from bottom left to top right (45 degree)",
                            {
                              ns: "bars",
                            }
                          )}
                        </Text>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="colorGradient grayScale"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundColorOpacity: 0,
                            backgroundImageColorGradient: {
                              type: "grayScale",
                              color1: "#dddddd",
                              color2: "#ffffff",
                              color3: "#dddddd",
                              gradientDegree: 60,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Gray Scale", { ns: "bars" })}
                        </Title>
                        <Text>
                          {il8next.t(
                            "style.3 colors from bottom left to top right (60 degree)",
                            {
                              ns: "bars",
                            }
                          )}
                        </Text>
                      </div>
                    </Layout.Section>
                  </Layout>
                </Box>

                <Box padding={"1-5"}>
                  <Layout columns="2 - symmetric">
                    <Layout.Section>
                      <Label>
                        {il8next.t("style.Gradient color 1", { ns: "bars" })}
                      </Label>
                      <ColorPickerInput
                        defaultColor={style.backgroundImageColorGradient.color1}
                        onColorChange={(color) =>
                          setStyle({
                            ...style,
                            backgroundImageColorGradient: {
                              ...style.backgroundImageColorGradient,
                              color1: color,
                            },
                          })
                        }
                        hasError={localErrors.color1}
                      />
                    </Layout.Section>
                    <Layout.Section>
                      <Label>
                        {il8next.t("style.Gradient color 2", { ns: "bars" })}
                      </Label>
                      <ColorPickerInput
                        defaultColor={style.backgroundImageColorGradient.color2}
                        onColorChange={(color) =>
                          setStyle({
                            ...style,
                            backgroundImageColorGradient: {
                              ...style.backgroundImageColorGradient,
                              color2: color,
                            },
                          })
                        }
                        hasError={localErrors.color2}
                      />
                    </Layout.Section>
                    {style.backgroundImageColorGradient.type === "dawn" ||
                    style.backgroundImageColorGradient.type === "river" ||
                    style.backgroundImageColorGradient.type === "grayScale" ? (
                      <Layout.Section>
                        <Label>
                          {il8next.t("style.Gradient color 3", { ns: "bars" })}
                        </Label>
                        <ColorPickerInput
                          defaultColor={
                            style.backgroundImageColorGradient.color3
                          }
                          onColorChange={(color) =>
                            setStyle({
                              ...style,
                              backgroundImageColorGradient: {
                                ...style.backgroundImageColorGradient,
                                color3: color,
                              },
                            })
                          }
                          hasError={localErrors.color3}
                        />
                      </Layout.Section>
                    ) : null}
                  </Layout>
                </Box>

                <Box padding="1-5">
                  <Label htmlFor="styleBackgroundImageGradientDegree">
                    {il8next.t("style.Gradient Degree", { ns: "bars" })}:{" "}
                    {style.backgroundImageColorGradient.gradientDegree}
                  </Label>
                  <input
                    type="range"
                    id="styleBackgroundImageGradientDegree"
                    name="styleBackgroundImageGradientDegree"
                    min="0"
                    max="360"
                    step="1"
                    value={style.backgroundImageColorGradient.gradientDegree}
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) =>
                      setStyle({
                        ...style,
                        backgroundImageColorGradient: {
                          ...style.backgroundImageColorGradient,
                          gradientDegree: e.target.value,
                        },
                      })
                    }
                  />
                  <Text>
                    {il8next.t(
                      "style.The range is from 0 to 360. Set to 0 for horizontal gradient, and to 90 for vertical gradient",
                      { ns: "bars" }
                    )}
                  </Text>
                </Box>
              </Box>
            )}

            {style.backgroundImageStyle === "patternImage" && (
              <Box padding="1-5">
                <Box padding="1">
                  <Label htmlFor="styleBackgroundImagePatternImage">
                    {il8next.t("style.Select a pattern image", { ns: "bars" })}
                  </Label>
                  <Layout columns="2 - symmetric">
                    <Layout.Section>
                      <div
                        className="patternImage christmas1"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "christmas1",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Christmas", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage christmas2"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "christmas2",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Christmas", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage sales"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "sales",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Sales", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage blackFriday"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "blackFriday",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Black Friday", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage cyberMonday"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "cyberMonday",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Cyber Monday", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage sakura"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "sakura",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Sakura", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage acrylic"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "acrylic",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Acrylic", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage memphis"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "memphis",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Memphis", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage christmas3"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "christmas3",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Christmas", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage christmas4"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "christmas4",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Christmas", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage valentines1"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "valentines1",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Valentines", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage valentines2"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "valentines2",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Valentines", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage seigaiha"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "seigaiha",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Seigaiha", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage herb"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "herb",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Herb", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage stPatrick"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "stPatrick",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.St Patrick", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage pentagon"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "pentagon",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Pentagon", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage yellow"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "yellow",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Yellow", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage newYear1"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "newYear1",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.New Year", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage newYear2"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "newYear2",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.New Year", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage waves"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "waves",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Waves", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage dust"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "dust",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Dust", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage dark"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "dark",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Dark", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage halloween1"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "halloween1",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Halloween", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="patternImage halloween2"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundImagePatternImage: {
                              type: "halloween2",
                              file: null,
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Halloween", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                  </Layout>
                </Box>
                <Box padding="1">
                  <Label htmlFor="patternImageUpload">
                    {il8next.t("style.Or, upload your own image", {
                      ns: "bars",
                    })}
                    :
                  </Label>
                  {customPatternImage &&
                    style.backgroundImagePatternImage.type === "custom" && (
                      <img
                        src={customPatternImage}
                        alt="Preview"
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          objectFit: "contain",
                        }}
                      />
                    )}
                  {/* display image selected */}
                  <FileUploader
                    aspectRatio="none"
                    flexDirection="row"
                    height="3rem"
                    placeholder={il8next.t(
                      "style.Click to upload or drag and drop",
                      { ns: "bars" }
                    )}
                    width="100%"
                    accept="image/*"
                    onChange={(e) => handlePatternCustomImageUpload(e)}
                  />
                </Box>
              </Box>
            )}

            {style.backgroundImageStyle === "fittedImage" && (
              <Box padding="1-5">
                <Box padding="1">
                  <Label htmlFor="styleBackgroundImageFittedImage">
                    {il8next.t("style.Select an image", { ns: "bars" })}
                  </Label>
                  <Layout columns="2 - symmetric">
                    <Layout.Section>
                      <div
                        className="fittedImage fittedNewYear"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundFittedImage: {
                              ...style.backgroundFittedImage,
                              type: "newYear",
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.New Year", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="fittedImage fittedLunarNewYear"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundFittedImage: {
                              ...style.backgroundFittedImage,
                              type: "lunarNewYear",
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Lunar New Year", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="fittedImage fittedValentines"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundFittedImage: {
                              ...style.backgroundFittedImage,
                              type: "valentines",
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Valentines", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="fittedImage fittedHalloween"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundFittedImage: {
                              ...style.backgroundFittedImage,
                              type: "halloween",
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Halloween", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="fittedImage fittedThanksGiving"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundFittedImage: {
                              ...style.backgroundFittedImage,
                              type: "thanksGiving",
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Thanks Giving", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="fittedImage fittedBlackFriday"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundFittedImage: {
                              ...style.backgroundFittedImage,
                              type: "blackFriday",
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Black Friday", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="fittedImage fittedCyberMonday"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundFittedImage: {
                              ...style.backgroundFittedImage,
                              type: "cyberMonday",
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Cyber Monday", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                    <Layout.Section>
                      <div
                        className="fittedImage fittedChristmas"
                        onClick={() =>
                          setStyle({
                            ...style,
                            backgroundFittedImage: {
                              ...style.backgroundFittedImage,
                              type: "christmas",
                            },
                          })
                        }
                      >
                        <Title as="h3">
                          {il8next.t("style.Christmas", { ns: "bars" })}
                        </Title>
                      </div>
                    </Layout.Section>
                  </Layout>
                </Box>
                <Box padding="1">
                  <Label htmlFor="fittedImageUpload">
                    {il8next.t("style.Or, upload your own image", {
                      ns: "bars",
                    })}
                  </Label>
                  {customFittedImage &&
                    style.backgroundFittedImage.type === "custom" && (
                      <img
                        src={customFittedImage}
                        alt="Preview"
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          objectFit: "contain",
                        }}
                      />
                    )}
                  <FileUploader
                    aspectRatio="none"
                    flexDirection="row"
                    height="3rem"
                    placeholder={il8next.t(
                      "style.Click to upload or drag and drop",
                      { ns: "bars" }
                    )}
                    width="100%"
                    accept="image/*"
                    onChange={(e) => handleFittedCustomImageUpload(e)}
                  />
                </Box>
                <Box padding="1">
                  <FormField.Input
                    label={il8next.t("style.Scroll Speed", { ns: "bars" })}
                    appearance={
                      localErrors.fittedScrollSpeed ? "danger" : "neutral"
                    }
                    showHelpText={true}
                    helpText={
                      localErrors.fittedScrollSpeed
                        ? localErrors.fittedScrollSpeed
                        : il8next.t(
                            "style.Number of seconds to scroll from top to bottom. 0 means not scrolling",
                            { ns: "bars" }
                          )
                    }
                    id="fittedScrollSpeed"
                    value={style.backgroundFittedImage.scrollSpeed}
                    onChange={(e) =>
                      setStyle({
                        ...style,
                        backgroundFittedImage: {
                          ...style.backgroundFittedImage,
                          scrollSpeed: e.target.value,
                        },
                      })
                    }
                  />
                </Box>
              </Box>
            )}

            <Box padding="1">
              <Label htmlFor="styleButtonAnimation">
                {il8next.t("style.Button Animation Style", { ns: "bars" })}
              </Label>
              <Box display={"flex"} flexWrap={"wrap"}>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.buttonAnimationStyle === "none"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, buttonAnimationStyle: "none" })
                    }
                  >
                    {il8next.t("style.No Anmation", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.buttonAnimationStyle === "emitting"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, buttonAnimationStyle: "emitting" })
                    }
                  >
                    {il8next.t("style.Emitting", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.buttonAnimationStyle === "bounce"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, buttonAnimationStyle: "bounce" })
                    }
                  >
                    {il8next.t("style.Bounce", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.buttonAnimationStyle === "flash"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, buttonAnimationStyle: "flash" })
                    }
                  >
                    {il8next.t("style.Flash", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.buttonAnimationStyle === "rubberBand"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({
                        ...style,
                        buttonAnimationStyle: "rubberBand",
                      })
                    }
                  >
                    {il8next.t("style.Rubber Band", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.buttonAnimationStyle === "swing"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, buttonAnimationStyle: "swing" })
                    }
                  >
                    {il8next.t("style.Swing", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.buttonAnimationStyle === "tada"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, buttonAnimationStyle: "tada" })
                    }
                  >
                    {il8next.t("style.Tada", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.buttonAnimationStyle === "wobble"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, buttonAnimationStyle: "wobble" })
                    }
                  >
                    {il8next.t("style.Wobble", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.buttonAnimationStyle === "jello"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, buttonAnimationStyle: "jello" })
                    }
                  >
                    {il8next.t("style.Jello", { ns: "bars" })}
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.buttonAnimationStyle === "flip"
                        ? "primary"
                        : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, buttonAnimationStyle: "flip" })
                    }
                  >
                    {il8next.t("style.Flip", { ns: "bars" })}
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box padding="1">
              <Label htmlFor="styleFonts">
                {il8next.t("style.Message Fonts", { ns: "bars" })}
              </Label>
              <Box display={"flex"} flexWrap={"wrap"}>
                <Box padding={"1"}>
                  <Button
                    appearance={style.font === "Arial" ? "primary" : "neutral"}
                    onClick={() => setStyle({ ...style, font: "Arial" })}
                  >
                    <span
                      style={{
                        fontFamily: "Arial",
                      }}
                    >
                      Arial
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Helvetica" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Helvetica" })}
                  >
                    <span
                      style={{
                        fontFamily: "Helvetica",
                      }}
                    >
                      Helvetica
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Verdana" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Verdana" })}
                  >
                    <span
                      style={{
                        fontFamily: "Verdana",
                      }}
                    >
                      Verdana
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Courier" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Courier" })}
                  >
                    <span
                      style={{
                        fontFamily: "Courier",
                      }}
                    >
                      Courier
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Times New Roman" ? "primary" : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, font: "Times New Roman" })
                    }
                  >
                    <span
                      style={{
                        fontFamily: "Times New Roman",
                      }}
                    >
                      Times New Roman
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Georgia" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Georgia" })}
                  >
                    <span
                      style={{
                        fontFamily: "Georgia",
                      }}
                    >
                      Georgia
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Brush Script MT" ? "primary" : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, font: "Brush Script MT" })
                    }
                  >
                    <span
                      style={{
                        fontFamily: "Brush Script MT",
                      }}
                    >
                      Brush Script MT
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={style.font === "Serif" ? "primary" : "neutral"}
                    onClick={() => setStyle({ ...style, font: "Serif" })}
                  >
                    <span
                      style={{
                        fontFamily: "Serif",
                      }}
                    >
                      Serif
                    </span>
                  </Button>
                </Box>

                {/* Google fonts */}
                <Box padding={"1"}>
                  <Button
                    appearance={style.font === "Lato" ? "primary" : "neutral"}
                    onClick={() => setStyle({ ...style, font: "Lato" })}
                  >
                    <span
                      style={{
                        fontFamily: "Lato",
                      }}
                    >
                      Lato
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={style.font === "Roboto" ? "primary" : "neutral"}
                    onClick={() => setStyle({ ...style, font: "Roboto" })}
                  >
                    <span
                      style={{
                        fontFamily: "Roboto",
                      }}
                    >
                      Roboto
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Josefin Sans" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Josefin Sans" })}
                  >
                    <span
                      style={{
                        fontFamily: "Josefin Sans",
                      }}
                    >
                      Josefin Sans
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Lobster" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Lobster" })}
                  >
                    <span
                      style={{
                        fontFamily: "Lobster",
                      }}
                    >
                      Lobster
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Open Sans" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Open Sans" })}
                  >
                    <span
                      style={{
                        fontFamily: "Open Sans",
                      }}
                    >
                      Open Sans
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Poiret One" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Poiret One" })}
                  >
                    <span
                      style={{
                        fontFamily: "Poiret One",
                      }}
                    >
                      Poiret One
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Dancing Script" ? "primary" : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, font: "Dancing Script" })
                    }
                  >
                    <span
                      style={{
                        fontFamily: "Dancing Script",
                      }}
                    >
                      Dancing Script
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Bangers" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Bangers" })}
                  >
                    <span
                      style={{
                        fontFamily: "Bangers",
                      }}
                    >
                      Bangers
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Play Fair Display" ? "primary" : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, font: "Play Fair Display" })
                    }
                  >
                    <span
                      style={{
                        fontFamily: "Play Fair Display",
                      }}
                    >
                      Play Fair Display
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={style.font === "Chewy" ? "primary" : "neutral"}
                    onClick={() => setStyle({ ...style, font: "Chewy" })}
                  >
                    <span
                      style={{
                        fontFamily: "Chewy",
                      }}
                    >
                      Chewy
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Quicksand" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Quicksand" })}
                  >
                    <span
                      style={{
                        fontFamily: "Quicksand",
                      }}
                    >
                      Quicksand
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Great Vibes" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Great Vibes" })}
                  >
                    <span
                      style={{
                        fontFamily: "Great Vibes",
                      }}
                    >
                      Great Vibes
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Righteous" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Righteous" })}
                  >
                    <span
                      style={{
                        fontFamily: "Righteous",
                      }}
                    >
                      Righteous
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Crafty Girls" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Crafty Girls" })}
                  >
                    <span
                      style={{
                        fontFamily: "Crafty Girls",
                      }}
                    >
                      Crafty Girls
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Mystery Quest" ? "primary" : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, font: "Mystery Quest" })
                    }
                  >
                    <span
                      style={{
                        fontFamily: "Mystery Quest",
                      }}
                    >
                      Mystery Quest
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Montserrat" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Montserrat" })}
                  >
                    <span
                      style={{
                        fontFamily: "Montserrat",
                      }}
                    >
                      Montserrat
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={style.font === "Oswald" ? "primary" : "neutral"}
                    onClick={() => setStyle({ ...style, font: "Oswald" })}
                  >
                    <span
                      style={{
                        fontFamily: "Oswald",
                      }}
                    >
                      Oswald
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Unica One" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Unica One" })}
                  >
                    <span
                      style={{
                        fontFamily: "Unica One",
                      }}
                    >
                      Unica One
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={style.font === "Mulish" ? "primary" : "neutral"}
                    onClick={() => setStyle({ ...style, font: "Mulish" })}
                  >
                    <span
                      style={{
                        fontFamily: "Mulish",
                      }}
                    >
                      Mulish
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Carter One" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Carter One" })}
                  >
                    <span
                      style={{
                        fontFamily: "Carter One",
                      }}
                    >
                      Carter One
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Varela Round" ? "primary" : "neutral"
                    }
                    onClick={() => setStyle({ ...style, font: "Varela Round" })}
                  >
                    <span
                      style={{
                        fontFamily: "Varela Round",
                      }}
                    >
                      Varela Round
                    </span>
                  </Button>
                </Box>
                <Box padding={"1"}>
                  <Button
                    appearance={
                      style.font === "Julius Sans One" ? "primary" : "neutral"
                    }
                    onClick={() =>
                      setStyle({ ...style, font: "Julius Sans One" })
                    }
                  >
                    <span
                      style={{
                        fontFamily: "Julius Sans One",
                      }}
                    >
                      Julius Sans One
                    </span>
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box padding="1">
              <FormField.Input
                label={il8next.t("style.Font Size", { ns: "bars" })}
                showHelpText={true}
                helpText={
                  localErrors.fontSize
                    ? localErrors.fontSize
                    : il8next.t(
                        "style.If the bar is targeted to both desktops and mobile devices, the font size will be capped on mobile devices for optimal display",
                        { ns: "bars" }
                      )
                }
                appearance={localErrors.fontSize ? "danger" : "neutral"}
                id="fontSize"
                type="number"
                onChange={(e) =>
                  setStyle({ ...style, fontSize: e.target.value })
                }
                value={style.fontSize}
                min={0}
              />
            </Box>
            <Box padding="1">
              <FormField.Input
                label={il8next.t("style.Bar Padding", { ns: "bars" })}
                showHelpText={true}
                helpText={
                  localErrors.barPadding
                    ? localErrors.barPadding
                    : il8next.t(
                        "style.Space between the text and the upper/lower borders. If the bar is targeted to both desktops and mobile devices, the font size will be capped on mobile devices for optimal display",
                        { ns: "bars" }
                      )
                }
                appearance={localErrors.barPadding ? "danger" : "neutral"}
                id="barPadding"
                type="number"
                onChange={(e) =>
                  setStyle({ ...style, barPadding: e.target.value })
                }
                value={style.barPadding}
                min={0}
              />
            </Box>
            <Box padding="1">
              <FormField.Input
                label={il8next.t("style.Interval between bar displays", {
                  ns: "bars",
                })}
                showHelpText={true}
                helpText={
                  localErrors.intervalBetweenBarDisplay
                    ? localErrors.intervalBetweenBarDisplay
                    : il8next.t(
                        "style.Bar will only load if visiting a new page this many seconds after it last loaded",
                        {
                          ns: "bars",
                        }
                      )
                }
                appearance={
                  localErrors.intervalBetweenBarDisplay ? "danger" : "neutral"
                }
                id="intervalBetweenBarDisplay"
                type="number"
                onChange={(e) =>
                  setStyle({
                    ...style,
                    intervalBetweenBarDisplay: e.target.value,
                  })
                }
                value={style.intervalBetweenBarDisplay}
                min={0}
              />
            </Box>

            <Box padding="1">
              <FormField.Input
                label={il8next.t(
                  "style.Seconds to display before bar disappears",
                  {
                    ns: "bars",
                  }
                )}
                showHelpText={true}
                helpText={
                  localErrors.secondsToDisplayBeforeBarDissapears
                    ? localErrors.secondsToDisplayBeforeBarDissapears
                    : il8next.t("style.Bar will not disappear if set to 0", {
                        ns: "bars",
                      })
                }
                appearance={
                  localErrors.secondsToDisplayBeforeBarDissapears
                    ? "danger"
                    : "neutral"
                }
                id="secondsToDisplayBeforeBarDissapears"
                type="number"
                onChange={(e) =>
                  setStyle({
                    ...style,
                    secondsToDisplayBeforeBarDissapears: e.target.value,
                  })
                }
                value={style.secondsToDisplayBeforeBarDissapears}
                min={0}
              />
            </Box>
            <Box padding="1">
              <FormField.Input
                label={il8next.t("style.Time to fade in", { ns: "bars" })}
                showHelpText={true}
                helpText={
                  localErrors.timeToFadeIn
                    ? localErrors.timeToFadeIn
                    : il8next.t("style.Bar will not fade if set to 0", {
                        ns: "bars",
                      })
                }
                appearance={localErrors.timeToFadeIn ? "danger" : "neutral"}
                id="timeToFadeIn"
                type="number"
                onChange={(e) =>
                  setStyle({
                    ...style,
                    timeToFadeIn: e.target.value,
                  })
                }
                value={style.timeToFadeIn}
                min={0}
              />
            </Box>
          </Card>
        </Box>
      </Accordion.Body>
    </Accordion.Item>
  );
}
