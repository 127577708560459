import React, { useEffect, useState } from "react";
import { DataList } from "@nimbus-ds/patterns";
import { Text, Tag, Box, Icon, Button, Toggle } from "@nimbus-ds/components";
import Swal from "sweetalert2";

import {
  ChevronDownIcon,
  CreditCardIcon,
  BoxUnpackedIcon,
} from "@nimbus-ds/icons";
import { ChartIcon, ClickIcon, EyeIcon, TrashIcon } from "../ui/Icons";
import { Link } from "react-router-dom";
import il8next from "../../I18n";

const BarDataList = ({ onDeleteBar, onBarStatusChange, fetchedBars }) => {
  const [bars, setBars] = useState(fetchedBars);

  useEffect(() => {
    setBars(fetchedBars);
  }, [fetchedBars]);

  const handleToggle = async (clickedBarId) => {
    // Find the clicked bar
    const clickedBar = bars.find((bar) => bar.id === clickedBarId);
    const newStatus = clickedBar.status === "active" ? "inactive" : "active";

    // Send API request to update the bar status
    const response = await onBarStatusChange(clickedBarId, newStatus);
    // If the API call is successful, update the local state
    if (!response.success) return;
    setBars((prevBars) =>
      prevBars.map((bar) => {
        if (bar.id === clickedBarId) {
          return { ...bar, status: newStatus };
        } else {
          return { ...bar, status: "inactive" };
        }
      })
    );
  };

  return bars.map((bar) => (
    <DataList key={bar.id}>
      <DataList.Row gap="4">
        <Box display="flex" justifyContent="space-between">
          <Text
            fontWeight="small"
            fontSize="1rem"
            color="primary-interactive"
          ></Text>
          <Text>{new Date(bar.createdAt).toLocaleString()}</Text>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={"column"}
          gap={2}
        >
          <span
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span
              style={{
                marginRight: "10px",
              }}
            >
              <Toggle
                name={`bar-${bar.id}`}
                checked={bar.status === "active"}
                onClick={() => handleToggle(bar.id)}
              />
            </span>

            {bar.status === "active" ? (
              <Tag appearance="success">
                <Text color="primary-textLow">
                  {" "}
                  {il8next.t("Active", { ns: "bars" })}
                </Text>
              </Tag>
            ) : (
              <Tag appearance="warning">
                <Text color="primary-textLow">
                  {il8next.t("Paused", { ns: "bars" })}
                </Text>
              </Tag>
            )}
          </span>
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Link to={`bar/${bar.id}`}>
            <Text>{bar.name}</Text>
          </Link>
          <span
            onClick={() => {
              Swal.fire({
                title: il8next.t("Are You Sure"),
                text: il8next.t("You want be able to revert this"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: il8next.t("Yes, delete it"),
              }).then((result) => {
                if (result.isConfirmed) {
                  onDeleteBar(bar.id);
                }
              });
            }}
          >
            <TrashIcon
              size={"sm"}
              style={{
                color: "red",
                cursor: "pointer",
              }}
            />
          </span>
        </div>
      </DataList.Row>
    </DataList>
  ));
};

export default BarDataList;
