import {
  Accordion,
  Box,
  Card,
  Checkbox,
  Input,
  Label,
  Radio,
  Select,
  Text,
} from "@nimbus-ds/components";
import { FormField, Layout } from "@nimbus-ds/patterns";
import DatePicker from "../../../ui/DatePicker";
import { useContext, useEffect, useState } from "react";
import { BarContext } from "../../../../context/BarContext";
import { ErrorIcon } from "../../../ui/Icons";
import { format } from "date-fns";
import il8next from "../../../../I18n";

export default function Timer({ errors }) {
  const { timer, setTimer } = useContext(BarContext);
  const [localErrors, setLocalErrors] = useState({});
  // Update local errors when errors change
  useEffect(() => {
    if (errors && errors.error) {
      const newErrors = errors?.error?.reduce((acc, err) => {
        if (err.path[0] === "timer") {
          acc[err.path[1]] = err.message;
        }
        return acc;
      }, {});
      setLocalErrors(newErrors);
    } else {
      setLocalErrors({});
    }
  }, [errors]);

  const formatTime = (dateString) => {
    if (!dateString) return "";
    // Parse the date string into a Date object
    const date = new Date(dateString);
    // Format the time part (hours and minutes) using date-fns
    const formattedTime = format(date, "HH:mm");

    return formattedTime;
  };

  const changeTimeValue = (date, time) => {
    const [hours, minutes] = time.split(":");
    const newDate = new Date(date);
    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    return newDate;
  };

  const changeDateValue = (date, newDate) => {
    if (!newDate) return date;
    const newDateObj = new Date(newDate);
    newDateObj.setHours(date.getHours());
    newDateObj.setMinutes(date.getMinutes());
    return newDateObj;
  };

  // funcion para convertir los time strings a formato "HH:MM"
  function convertTimeFormat(timeString) {
    // Check if the time is already in the format "HH:MM"
    if (/^\d{2}:\d{2}$/.test(timeString)) {
      return timeString;
    }

    // Split the time string by colons and dot
    const [hours, minutes] = timeString.split(/[:.]/);

    // Format the time as "HH:MM"
    const formattedTime = `${hours}:${minutes}`;

    return formattedTime;
  }

  return (
    <Accordion.Item index="1">
      <Accordion.Header
        title={il8next.t("timer.Timer configuration", { ns: "bars" })}
        icon={
          Object.keys(localErrors).length > 0 ? (
            <ErrorIcon
              style={{
                color: "red",
              }}
            />
          ) : null
        }
        subtitle={il8next.t("timer.Configure the timer to display on the bar", {
          ns: "bars",
        })}
      />
      <Accordion.Body>
        <Box borderWidth="1" padding="2" width="100%">
          <Card>
            <Box padding="1">
              <FormField.Select
                appearance="neutral"
                id="timerType"
                name="timerType"
                label={il8next.t("timer.Timer type", { ns: "bars" })}
                onChange={(e) =>
                  setTimer({ ...timer, timerType: e.target.value })
                }
                value={timer.timerType}
              >
                <Select.Option
                  label={il8next.t("timer.Standard", { ns: "bars" })}
                  value="standard"
                />
                <Select.Option
                  label={il8next.t("timer.Daily", { ns: "bars" })}
                  value="daily"
                />
                <Select.Option
                  label={il8next.t("timer.Interval", { ns: "bars" })}
                  value="interval"
                />
                <Select.Option
                  label={il8next.t("timer.Session", { ns: "bars" })}
                  value="session"
                />
              </FormField.Select>
            </Box>

            {timer.timerType === "standard" && (
              <>
                <Box padding="1">
                  <Label htmlFor="timerStandardStart">
                    {il8next.t("timer.Start displaying timer", { ns: "bars" })}
                  </Label>
                  <Radio
                    id="now"
                    name="timerStandardStart"
                    label={il8next.t("timer.Now", { ns: "bars" })}
                    onChange={(e) =>
                      setTimer({ ...timer, timerStandardStart: "now" })
                    }
                    checked={timer.timerStandardStart === "now"}
                  />
                  <Radio
                    id="schedule"
                    name="timerStandardStart"
                    label={il8next.t("timer.At scheduled time", { ns: "bars" })}
                    onChange={(e) =>
                      setTimer({ ...timer, timerStandardStart: "schedule" })
                    }
                    checked={timer.timerStandardStart === "schedule"}
                  />
                  {timer.timerStandardStart === "schedule" && (
                    <Box padding="3-5">
                      <Layout columns="2 - symmetric">
                        <Layout.Section>
                          <DatePicker
                            onDateChange={(date) =>
                              setTimer({
                                ...timer,
                                timerStandardStartDateTime: changeDateValue(
                                  timer.timerStandardStartDateTime,
                                  date
                                ),
                              })
                            }
                            defaultDate={timer.timerStandardStartDateTime}
                          />
                        </Layout.Section>
                        <Layout.Section>
                          <Input
                            type="time"
                            value={formatTime(timer.timerStandardStartDateTime)}
                            onChange={(e) =>
                              setTimer({
                                ...timer,
                                timerStandardStartDateTime: changeTimeValue(
                                  timer.timerStandardStartDateTime,
                                  e.target.value
                                ),
                              })
                            }
                          />
                        </Layout.Section>
                      </Layout>
                    </Box>
                  )}
                </Box>
                <Box padding="1">
                  <Label htmlFor="timerStandardCountdown">
                    {il8next.t("timer.Countdown to", { ns: "bars" })}
                  </Label>
                  {/* Falta temrinar de hacerlo dinamico y select time */}
                  <Layout columns="2 - symmetric">
                    <Layout.Section>
                      <DatePicker
                        onDateChange={(date) =>
                          setTimer({
                            ...timer,
                            timerStandardCountdownDateTime: changeDateValue(
                              timer.timerStandardCountdownDateTime,
                              date
                            ),
                          })
                        }
                        defaultDate={timer.timerStandardCountdownDateTime}
                      />
                    </Layout.Section>
                    <Layout.Section>
                      <Input
                        type="time"
                        value={formatTime(timer.timerStandardCountdownDateTime)}
                        onChange={(e) =>
                          setTimer({
                            ...timer,
                            timerStandardCountdownDateTime: changeTimeValue(
                              timer.timerStandardCountdownDateTime,
                              e.target.value
                            ),
                          })
                        }
                      />
                    </Layout.Section>
                  </Layout>
                </Box>
              </>
            )}

            {timer.timerType === "daily" && (
              <>
                <Box padding="1">
                  <Layout columns="2 - symmetric">
                    <Layout.Section>
                      <Label htmlFor="timerDailyStartTime">
                        {il8next.t("timer.Start time", { ns: "bars" })}
                      </Label>
                      <Input
                        id="timerDailyStartTime"
                        type="time"
                        value={convertTimeFormat(timer.timerDailyStartTime)}
                        onChange={(e) =>
                          setTimer({
                            ...timer,
                            timerDailyStartTime: e.target.value,
                          })
                        }
                      />
                    </Layout.Section>
                    <Layout.Section>
                      <Label htmlFor="timerDailyEndTime">
                        {il8next.t("timer.End time", { ns: "bars" })}
                      </Label>
                      <Input
                        id="timerDailyEndTime"
                        type="time"
                        value={convertTimeFormat(timer.timerDailyEndTime)}
                        onChange={(e) =>
                          setTimer({
                            ...timer,
                            timerDailyEndTime: e.target.value,
                          })
                        }
                      />
                    </Layout.Section>
                  </Layout>
                </Box>
                <Box padding="1">
                  <Label htmlFor="timerDailyDays">
                    {il8next.t("timer.Select the days for the bar to display", {
                      ns: "bars",
                    })}
                  </Label>
                  <Checkbox
                    name="monday"
                    label={il8next.t("timer.Monday", { ns: "bars" })}
                    checked={timer.timerDailyDays.monday}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerDailyDays: {
                          ...timer.timerDailyDays,
                          monday: !timer.timerDailyDays.monday,
                        },
                      })
                    }
                  />
                  <Checkbox
                    name="tuesday"
                    label={il8next.t("timer.Tuesday", { ns: "bars" })}
                    checked={timer.timerDailyDays.tuesday}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerDailyDays: {
                          ...timer.timerDailyDays,
                          tuesday: !timer.timerDailyDays.tuesday,
                        },
                      })
                    }
                  />
                  <Checkbox
                    name="wednesday"
                    label={il8next.t("timer.Wednesday", { ns: "bars" })}
                    checked={timer.timerDailyDays.wednesday}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerDailyDays: {
                          ...timer.timerDailyDays,
                          wednesday: !timer.timerDailyDays.wednesday,
                        },
                      })
                    }
                  />
                  <Checkbox
                    name="thursday"
                    label={il8next.t("timer.Thursday", { ns: "bars" })}
                    checked={timer.timerDailyDays.thursday}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerDailyDays: {
                          ...timer.timerDailyDays,
                          thursday: !timer.timerDailyDays.thursday,
                        },
                      })
                    }
                  />
                  <Checkbox
                    name="friday"
                    label={il8next.t("timer.Friday", { ns: "bars" })}
                    checked={timer.timerDailyDays.friday}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerDailyDays: {
                          ...timer.timerDailyDays,
                          friday: !timer.timerDailyDays.friday,
                        },
                      })
                    }
                  />
                  <Checkbox
                    name="saturday"
                    label={il8next.t("timer.Saturday", { ns: "bars" })}
                    checked={timer.timerDailyDays.saturday}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerDailyDays: {
                          ...timer.timerDailyDays,
                          saturday: !timer.timerDailyDays.saturday,
                        },
                      })
                    }
                  />
                  <Checkbox
                    name="sunday"
                    label={il8next.t("timer.Sunday", { ns: "bars" })}
                    checked={timer.timerDailyDays.sunday}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerDailyDays: {
                          ...timer.timerDailyDays,
                          sunday: !timer.timerDailyDays.sunday,
                        },
                      })
                    }
                  />
                </Box>
                <Box padding="1">
                  <FormField.Select
                    appearance="neutral"
                    id="timerDailyVisitorRecurrence"
                    name="timerDailyVisitorRecurrence"
                    label={il8next.t(
                      "timer.Visitor will only see one recurring cycle",
                      {
                        ns: "bars",
                      }
                    )}
                    helpText={il8next.t(
                      "timer.The same visitor will only see 1 cycle between the Start Time and End Time",
                      {
                        ns: "bars",
                      }
                    )}
                    showHelpText={true}
                    value={timer.timerDailyVisitorRecurrence}
                    onChange={(e) =>
                      setTimer({
                        ...timer,
                        timerDailyVisitorRecurrence:
                          e.target.value === "true" ? true : false,
                      })
                    }
                  >
                    <Select.Option label={il8next.t("No")} value={false} />
                    <Select.Option label={il8next.t("Yes")} value={true} />
                  </FormField.Select>
                </Box>
                <Box padding="1">
                  <Label htmlFor="timerDailyCampaignStart">
                    {il8next.t("timer.Campaign start time", { ns: "bars" })}
                  </Label>
                  <Radio
                    id="timerDailyCampaignStartNow"
                    name="timerDailyCampaignStart"
                    label={il8next.t("timer.Start displaying now", {
                      ns: "bars",
                    })}
                    checked={timer.timerDailyCampaignStart === "now"}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerDailyCampaignStart: "now",
                      })
                    }
                  />
                  <Radio
                    id="timerDailyCampaignStartSchedule"
                    name="timerDailyCampaignStart"
                    label={il8next.t(
                      "timer.Start displaying at scheduled time",
                      { ns: "bars" }
                    )}
                    checked={timer.timerDailyCampaignStart === "schedule"}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerDailyCampaignStart: "schedule",
                      })
                    }
                  />
                  {/* Only show if campaign start time = schedule */}
                  {/* Falta temrinar de hacerlo dinamico y select time */}
                  {timer.timerDailyCampaignStart === "schedule" && (
                    <Box padding={"3-5"}>
                      <Layout columns="2 - symmetric">
                        <Layout.Section>
                          <DatePicker
                            defaultDate={timer.timerDailyCampaignStartDateTime}
                            onDateChange={(date) =>
                              setTimer({
                                ...timer,
                                timerDailyCampaignStartDateTime:
                                  changeDateValue(
                                    timer.timerDailyCampaignStartDateTime,
                                    date
                                  ),
                              })
                            }
                          />
                        </Layout.Section>
                        <Layout.Section>
                          <Input
                            type="time"
                            value={formatTime(
                              timer.timerDailyCampaignStartDateTime
                            )}
                            onChange={(e) =>
                              setTimer({
                                ...timer,
                                timerDailyCampaignStartDateTime:
                                  changeTimeValue(
                                    timer.timerDailyCampaignStartDateTime,
                                    e.target.value
                                  ),
                              })
                            }
                          />
                        </Layout.Section>
                      </Layout>
                    </Box>
                  )}
                </Box>
                <Box padding="1">
                  <Label htmlFor="timerDailyCampaignEnd">
                    {il8next.t("timer.Campaign end time", { ns: "bars" })}
                  </Label>
                  <Radio
                    id="timerDailyCampaignEndPaused"
                    name="timerDailyCampaignEnd"
                    label={il8next.t(
                      "timer.Continue displaying until bar is paused",
                      {
                        ns: "bars",
                      }
                    )}
                    checked={timer.timerDailyCampaignEnd === "paused"}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerDailyCampaignEnd: "paused",
                      })
                    }
                  />
                  <Radio
                    id="timerDailyCampaignEndSpecificDate"
                    name="timerDailyCampaignEnd"
                    label={il8next.t(
                      "timer.Stop displaying daily timer on specific date",
                      {
                        ns: "bars",
                      }
                    )}
                    checked={timer.timerDailyCampaignEnd === "specificDate"}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerDailyCampaignEnd: "specificDate",
                      })
                    }
                  />

                  {/* Only show if campaign end time = specific date */}
                  {/* Falta temrinar de hacerlo dinamico y select time */}
                  {timer.timerDailyCampaignEnd === "specificDate" && (
                    <Box padding={"3-5"}>
                      <Layout columns="2 - symmetric">
                        <Layout.Section>
                          <DatePicker
                            defaultDate={timer.timerDailyCampaignEndDateTime}
                            onDateChange={(date) =>
                              setTimer({
                                ...timer,
                                timerDailyCampaignEndDateTime: changeDateValue(
                                  timer.timerDailyCampaignEndDateTime,
                                  date
                                ),
                              })
                            }
                          />
                        </Layout.Section>
                        <Layout.Section>
                          <Input
                            type="time"
                            value={formatTime(
                              timer.timerDailyCampaignEndDateTime
                            )}
                            onChange={(e) =>
                              setTimer({
                                ...timer,
                                timerDailyCampaignEndDateTime: changeTimeValue(
                                  timer.timerDailyCampaignEndDateTime,
                                  e.target.value
                                ),
                              })
                            }
                          />
                        </Layout.Section>
                      </Layout>
                    </Box>
                  )}
                </Box>
              </>
            )}

            {timer.timerType === "interval" && (
              <>
                <Box padding="1">
                  <Layout columns="2 - symmetric">
                    <Layout.Section>
                      <Label htmlFor="timerIntervalCampaignStartDate">
                        {il8next.t("timer.Campaign start date", { ns: "bars" })}
                      </Label>
                      <DatePicker
                        defaultDate={timer.timerIntervalCampaignStartDateTime}
                        onDateChange={(date) =>
                          setTimer({
                            ...timer,
                            timerIntervalCampaignStartDateTime: changeDateValue(
                              timer.timerIntervalCampaignStartDateTime,
                              date
                            ),
                          })
                        }
                      />
                    </Layout.Section>
                    <Layout.Section>
                      <Label htmlFor="timerIntervalCampaignStartTime">
                        {il8next.t("timer.Campaign start time", { ns: "bars" })}
                      </Label>
                      <Input
                        type="time"
                        value={formatTime(
                          timer.timerIntervalCampaignStartDateTime
                        )}
                        onChange={(e) =>
                          setTimer({
                            ...timer,
                            timerIntervalCampaignStartDateTime: changeTimeValue(
                              timer.timerIntervalCampaignStartDateTime,
                              e.target.value
                            ),
                          })
                        }
                      />
                    </Layout.Section>
                  </Layout>
                </Box>
                <Box padding="1">
                  <Layout columns="2 - symmetric">
                    <Layout.Section>
                      <FormField.Input
                        label={il8next.t("timer.Countdown length", {
                          ns: "bars",
                        })}
                        id="timerIntervalCountdownLength"
                        helpText={il8next.t("timer.In hours", { ns: "bars" })}
                        type="number"
                        showHelpText={true}
                        value={timer.timerIntervalCountdownLength}
                        onChange={(e) =>
                          setTimer({
                            ...timer,
                            timerIntervalCountdownLength: e.target.value,
                          })
                        }
                        min={1}
                      />
                    </Layout.Section>
                    <Layout.Section>
                      <FormField.Input
                        label={il8next.t("timer.Pause length", { ns: "bars" })}
                        id="timerIntervalPauseLength"
                        helpText={il8next.t("timer.In hours", { ns: "bars" })}
                        showHelpText={true}
                        type="number"
                        value={timer.timerIntervalPauseLength}
                        onChange={(e) =>
                          setTimer({
                            ...timer,
                            timerIntervalPauseLength: e.target.value,
                          })
                        }
                        min={0}
                      />
                    </Layout.Section>
                  </Layout>
                  <Text>
                    {il8next.t("timer.This timer will begin displaying at", {
                      timerIntervalCampaignStartDateTime: new Date(
                        timer?.timerIntervalCampaignStartDateTime
                      ).toLocaleString(il8next.language, {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      }),
                      timerIntervalCountdownLength:
                        timer?.timerIntervalCountdownLength,
                      timerIntervalPauseLength: timer?.timerIntervalPauseLength,
                      ns: "bars",
                    })}
                  </Text>
                </Box>
                <Box padding="1">
                  <Label htmlFor="timerIntervalCampaignEnd">
                    {il8next.t("timer.Campaign end time", { ns: "bars" })}
                  </Label>
                  <Radio
                    id="paused"
                    name="timerIntervalCampaignEnd"
                    label={il8next.t(
                      "timer.Continue displaying until bar is paused",
                      { ns: "bars" }
                    )}
                    checked={timer.timerIntervalCampaignEnd === "paused"}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerIntervalCampaignEnd: "paused",
                      })
                    }
                  />
                  <Radio
                    id="specificDate"
                    name="timerIntervalCampaignEnd"
                    label={il8next.t(
                      "timer.Stop displaying daily timer on specific date",
                      { ns: "bars" }
                    )}
                    checked={timer.timerIntervalCampaignEnd === "specificDate"}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerIntervalCampaignEnd: "specificDate",
                      })
                    }
                  />

                  {timer.timerIntervalCampaignEnd === "specificDate" && (
                    <Box padding="3-5">
                      <Layout columns="2 - symmetric">
                        <Layout.Section>
                          <DatePicker
                            defaultDate={timer.timerIntervalCampaignEndDateTime}
                            onDateChange={(date) =>
                              setTimer({
                                ...timer,
                                timerIntervalCampaignEndDateTime:
                                  changeDateValue(
                                    timer.timerIntervalCampaignEndDateTime,
                                    date
                                  ),
                              })
                            }
                          />
                        </Layout.Section>
                        <Layout.Section>
                          <Input
                            type="time"
                            value={formatTime(
                              timer.timerIntervalCampaignEndDateTime
                            )}
                            onChange={(e) =>
                              setTimer({
                                ...timer,
                                timerIntervalCampaignEndDateTime:
                                  changeTimeValue(
                                    timer.timerIntervalCampaignEndDateTime,
                                    e.target.value
                                  ),
                              })
                            }
                          />
                        </Layout.Section>
                      </Layout>
                    </Box>
                  )}
                </Box>
                <Box padding="1">
                  <FormField.Select
                    appearance="neutral"
                    id="timerIntervalVisitorRecurrence"
                    name="timerIntervalVisitorRecurrence"
                    label={il8next.t(
                      "timer.Hide recurring timer to repeat customers",
                      { ns: "bars" }
                    )}
                    helpText={il8next.t(
                      "timer.If set to yes, a customer who has already seen a timer cycle will not see another one during this campaign",
                      { ns: "bars" }
                    )}
                    showHelpText={true}
                    value={timer.timerIntervalVisitorRecurrence}
                    onChange={(e) =>
                      setTimer({
                        ...timer,
                        timerIntervalVisitorRecurrence:
                          e.target.value === "true",
                      })
                    }
                  >
                    <Select.Option label="No" value={false} />
                    <Select.Option label="Yes" value={true} />
                  </FormField.Select>
                </Box>
              </>
            )}

            {timer.timerType === "session" && (
              <>
                <Box padding="1">
                  <FormField.Input
                    label={il8next.t("timer.Timer length (in minutes)", {
                      ns: "bars",
                    })}
                    helpText={il8next.t(
                      "timer.When a customer first visits the store, a timer of this length will begin",
                      { ns: "bars" }
                    )}
                    showHelpText={true}
                    id="timerSessionLength"
                    placeholder=""
                    type="number"
                    value={timer.timerSessionLength}
                    onChange={(e) =>
                      setTimer({ ...timer, timerSessionLength: e.target.value })
                    }
                    min={1}
                  />
                </Box>
                <Box padding="1">
                  <FormField.Select
                    appearance="neutral"
                    id="timerSessionVisitorRecurrence"
                    name="timerSessionVisitorRecurrence"
                    label={il8next.t(
                      "timer.Hide recurring timer to repeat customers",
                      { ns: "bars" }
                    )}
                    helpText={il8next.t(
                      "timer.If set to yes, a customer who has already seen a timer cycle will not see another one during this campaign",
                      { ns: "bars" }
                    )}
                    showHelpText={true}
                    value={timer.timerSessionVisitorRecurrence}
                    onChange={(e) =>
                      setTimer({
                        ...timer,
                        timerSessionVisitorRecurrence:
                          e.target.value === "true",
                      })
                    }
                  >
                    <Select.Option label={il8next.t("No")} value={false} />
                    <Select.Option label={il8next.t("Yes")} value={true} />
                  </FormField.Select>
                </Box>
                <Box padding="1">
                  <Label htmlFor="timerSessionCampaignStart">
                    {il8next.t("timer.Campaign start time", { ns: "bars" })}
                  </Label>
                  <Radio
                    id="startNow"
                    name="timerSessionCampaignStart"
                    label={il8next.t("timer.Start displaying now", {
                      ns: "bars",
                    })}
                    checked={timer.timerSessionCampaignStart === "now"}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerSessionCampaignStart: "now",
                      })
                    }
                  />
                  <Radio
                    id="startSpecificDate"
                    name="timerSessionCampaignStart"
                    label={il8next.t(
                      "timer.Start displaying at scheduled time",
                      {
                        ns: "bars",
                      }
                    )}
                    checked={timer.timerSessionCampaignStart === "schedule"}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerSessionCampaignStart: "schedule",
                      })
                    }
                  />
                  {timer.timerSessionCampaignStart === "schedule" && (
                    <Box padding="3-5">
                      <Layout columns="2 - symmetric">
                        <Layout.Section>
                          <DatePicker
                            defaultDate={
                              timer.timerSessionCampaignStartDateTime
                            }
                            onDateChange={(date) =>
                              setTimer({
                                ...timer,
                                timerSessionCampaignStartDateTime:
                                  changeDateValue(
                                    timer.timerSessionCampaignStartDateTime,
                                    date
                                  ),
                              })
                            }
                          />
                        </Layout.Section>
                        <Layout.Section>
                          <Input
                            type="time"
                            value={formatTime(
                              timer.timerSessionCampaignStartDateTime
                            )}
                            onChange={(e) =>
                              setTimer({
                                ...timer,
                                timerSessionCampaignStartDateTime:
                                  changeTimeValue(
                                    timer.timerSessionCampaignStartDateTime,
                                    e.target.value
                                  ),
                              })
                            }
                          />
                        </Layout.Section>
                      </Layout>
                    </Box>
                  )}
                </Box>
                <Box padding="1">
                  <Label htmlFor="timerSessionCampaignEnd">
                    {il8next.t("timer.Campaign end time", { ns: "bars" })}
                  </Label>
                  <Radio
                    id="endUntilPaused"
                    name="timerSessionCampaignEnd"
                    label={il8next.t(
                      "timer.Continue displaying until bar is paused",
                      { ns: "bars" }
                    )}
                    checked={timer.timerSessionCampaignEnd === "untilPaused"}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerSessionCampaignEnd: "untilPaused",
                      })
                    }
                  />
                  <Radio
                    id="endSpecificDate"
                    name="timerSessionCampaignEnd"
                    label={il8next.t(
                      "timer.Stop displaying daily timer on specific date",
                      { ns: "bars" }
                    )}
                    checked={timer.timerSessionCampaignEnd === "specificDate"}
                    onChange={() =>
                      setTimer({
                        ...timer,
                        timerSessionCampaignEnd: "specificDate",
                      })
                    }
                  />
                  {timer.timerSessionCampaignEnd === "specificDate" && (
                    <Box padding="3-5">
                      <Layout columns="2 - symmetric">
                        <Layout.Section>
                          <DatePicker
                            defaultDate={timer.timerSessionCampaignEndDateTime}
                            onDateChange={(date) =>
                              setTimer({
                                ...timer,
                                timerSessionCampaignEndDateTime:
                                  changeDateValue(
                                    timer.timerSessionCampaignEndDateTime,
                                    date
                                  ),
                              })
                            }
                          />
                        </Layout.Section>
                        <Layout.Section>
                          <Input
                            type="time"
                            value={formatTime(
                              timer.timerSessionCampaignEndDateTime
                            )}
                            onChange={(e) =>
                              setTimer({
                                ...timer,
                                timerSessionCampaignEndDateTime:
                                  changeTimeValue(
                                    timer.timerSessionCampaignEndDateTime,
                                    e.target.value
                                  ),
                              })
                            }
                          />
                        </Layout.Section>
                      </Layout>
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Card>
        </Box>
      </Accordion.Body>
    </Accordion.Item>
  );
}
