import { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import { BarContext } from "../../../context/BarContext";
import { XIcon } from "../../ui/Icons";
import FlipCountdown from "./Timer/FlipClock/Countdown";
import PlainNumbersContdown from "./Timer/PlainNumbers/Countdown";
import TilesCountdown from "./Timer/Tiles/Countdown";

export default function Bar({ preview = true }) {
  const { content, style, mobilePreview } = useContext(BarContext);
  const [isVisible, setIsVisible] = useState(true);

  const [backgroundColor, setBackgroundColor] = useState();
  const [backgroundGradient, setBackgroundGradient] = useState();
  const [backgroundImage, setBackgroundImage] = useState();
  const barRef = useRef(null);
  const buttonRef = useRef(null);

  // actualiazamos las variables de css dependiendo de la config del user
  useEffect(() => {
    function hexToRgba(hex, alpha) {
      // Remove the hash at the start if it's there
      hex = hex.replace(/^#/, "");
      // Parse the hex color
      let r = parseInt(hex.substr(0, 2), 16);
      let g = parseInt(hex.substr(2, 2), 16);
      let b = parseInt(hex.substr(4, 2), 16);
      // Return the rgba color
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    setBackgroundColor(
      hexToRgba(style.backgroundColor, style.backgroundColorOpacity)
    );

    // solo si es colorGradient:
    if (style.backgroundImageStyle === "colorGradient") {
      const color1 = style.backgroundImageColorGradient.color1;
      const color2 = style.backgroundImageColorGradient.color2;
      const color3 = style.backgroundImageColorGradient.color3;
      const gradientDegree = style.backgroundImageColorGradient.gradientDegree;
      let gradient = `linear-gradient(${gradientDegree}deg, ${color1}, ${color2}`;
      if (color3) {
        gradient += `, ${color3}`;
      }
      gradient += ")";
      setBackgroundGradient(gradient);
    }

    // solo si es patternImage:
    if (style.backgroundImageStyle === "patternImage") {
      let imagePath;
      switch (style.backgroundImagePatternImage.type) {
        case "christmas1":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_christmas1.png";
          break;
        case "christmas2":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_christmas2.png";
          break;
        case "sales":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_sales.png";
          break;
        case "blackFriday":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_black_friday.png";
          break;
        case "cyberMonday":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_cyber_monday.png";
          break;
        case "sakura":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_sakura.png";
          break;
        case "acrylic":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_acrylic.png";
          break;
        case "memphis":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_memphis.png";
          break;
        case "christmas3":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_christmas3.png";
          break;
        case "christmas4":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_christmas4.png";
          break;
        case "valentines1":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_valentines1.png";
          break;
        case "valentines2":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_valentines2.png";
          break;
        case "seigaiha":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_seigaiha.png";
          break;
        case "herb":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_herb.png";
          break;
        case "stPatrick":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_st_patrick.png";
          break;
        case "pentagon":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_pentagon.png";
          break;
        case "yellow":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_yellow.png";
          break;
        case "newYear1":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_new_year1.png";
          break;
        case "newYear2":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_new_year2.png";
          break;
        case "waves":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_waves.png";
          break;
        case "dust":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_dust.png";
          break;
        case "dark":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_dark.png";
          break;
        case "halloween1":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_halloween1.png";
          break;
        case "halloween2":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/pattern/template_halloween2.png";
          break;
        case "custom":
          if (typeof style.backgroundImagePatternImage.file === "string") {
            imagePath = style.backgroundImagePatternImage.file;
          } else {
            imagePath = URL.createObjectURL(
              style.backgroundImagePatternImage.file
            );
          }
          break;
        default:
          imagePath = "";
      }

      setBackgroundImage(`url(${imagePath})`);
    }

    // solo si es fittedImage:
    if (style.backgroundImageStyle === "fittedImage") {
      let imagePath;
      switch (style.backgroundFittedImage.type) {
        case "newYear":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/fitted/template_new_year.jpg";
          break;
        case "lunarNewYear":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/fitted/template_lunar_new_year.jpg";
          break;
        case "valentines":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/fitted/template_valentines.jpg";
          break;
        case "halloween":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/fitted/template_halloween.jpg";
          break;
        case "thanksGiving":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/fitted/template_thanks_giving.jpg";
          break;
        case "blackFriday":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/fitted/template_black_friday.jpg";
          break;
        case "cyberMonday":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/fitted/template_cyber_monday.jpg";
          break;
        case "christmas":
          imagePath =
            "https://timerbar.s3.sa-east-1.amazonaws.com/images/bar/fitted/template_christmas.jpg";
          break;
        case "custom":
          if (typeof style.backgroundFittedImage.file === "string") {
            imagePath = style.backgroundFittedImage.file;
          } else {
            imagePath = URL.createObjectURL(style.backgroundFittedImage.file);
          }
          break;
        default:
          imagePath = "";
      }
      setBackgroundImage(`url(${imagePath})`);

      // Set animation duration based on scroll speed
      const scrollSpeed = style.backgroundFittedImage.scrollSpeed;
      if (scrollSpeed > 0 && barRef.current) {
        const duration = `${scrollSpeed}s`;
        barRef.current.style.animation = `backgroundAnimation ${duration} linear infinite alternate`;
      } else {
        barRef.current.style.animation = "none";
      }
    }

    //button animation
    if (buttonRef.current) {
      if (style.buttonAnimationStyle === "none") {
        buttonRef.current.className = "";
        buttonRef.current.classList.add("callToAction");
      } else {
        switch (style.buttonAnimationStyle) {
          case "emitting":
            buttonRef.current.className = "";
            buttonRef.current.classList.add("callToAction", "emitting");
            break;
          case "bounce":
            buttonRef.current.className = "";
            buttonRef.current.classList.add("callToAction", "bounce");
            break;
          case "flash":
            buttonRef.current.className = "";
            buttonRef.current.classList.add("callToAction", "flash");
            break;
          case "rubberBand":
            buttonRef.current.className = "";
            buttonRef.current.classList.add("callToAction", "rubberBand");
            break;
          case "swing":
            buttonRef.current.className = "";
            buttonRef.current.classList.add("callToAction", "swing");
            break;
          case "tada":
            buttonRef.current.className = "";
            buttonRef.current.classList.add("callToAction", "tada");
            break;
          case "wobble":
            buttonRef.current.className = "";
            buttonRef.current.classList.add("callToAction", "wobble");
            break;
          case "jello":
            buttonRef.current.className = "";
            buttonRef.current.classList.add("callToAction", "jello");
            break;
          case "flip":
            buttonRef.current.className = "";
            buttonRef.current.classList.add("callToAction", "flip");
            break;
          default:
            buttonRef.current.className = "";
            buttonRef.current.classList.add("callToAction");
        }
      }
      buttonRef.current.style.fontFamily = style.font;
    }

    // set font
    document.documentElement.style.setProperty("--font-family", style.font);
    // set bar padding

    const padding = Math.min(
      Math.max(0, parseInt(style.barPadding, 10) || 0),
      25
    );

    document.documentElement.style.setProperty("--bar-padding", `${padding}px`);

    document.documentElement.style.setProperty("--text-color", style.textColor);

    document.documentElement.style.setProperty(
      "--font-size",
      `${style.fontSize}px`
    );
    document.documentElement.style.setProperty(
      "--button-bg-color",
      style.buttonBackgroundColor
    );
    document.documentElement.style.setProperty(
      "--button-text-color",
      style.buttonTextColor
    );
    document.documentElement.style.setProperty(
      "-timer-digit-color",
      style.timerDigitColor
    );
    document.documentElement.style.setProperty(
      "--timer-colon-separator-color",
      style.colonSeparatorColor
    );
  }, [style]);

  // add 30 days to date default
  const targetDate = new Date();
  targetDate.setDate(targetDate.getDate() + 30);
  if (!isVisible) return null;

  return (
    <div
      className="bar"
      ref={barRef}
      onClick={() => {
        if (content.contentCallToAction !== "entireBarClickable") return;
        if (preview) return;
        if (content.contentBarOpenNewTab) {
          window.open(content.contentBarLinkUrl, "_blank"); // open link in new tab
        } else {
          window.location.href = content.contentBarLinkUrl; // open link in same tab
        }
      }}
      style={{
        display: mobilePreview ? "flex" : "flex",
        justifyContent: mobilePreview ? "inherit" : "center",
        gap: "10px",
        flexDirection: mobilePreview ? "row" : "column",
        cursor:
          content.contentCallToAction === "entireBarClickable"
            ? "pointer"
            : "default",
        // backgroundColor:
        //   style.backgroundImageStyle === "noImage"
        //     ? backgroundColor
        //     : "inherit",
        backgroundColor: backgroundColor,
        backgroundImage:
          style.backgroundImageStyle === "colorGradient"
            ? backgroundGradient
            : style.backgroundImageStyle === "patternImage" ||
              style.backgroundImageStyle === "fittedImage"
            ? backgroundImage
            : "inherit",
        backgroundSize:
          style.backgroundImageStyle === "fittedImage" ? "cover" : "inherit",
        backgroundRepeat:
          style.backgroundImageStyle === "fittedImage"
            ? "no-repeat"
            : "inherit",
      }}
    >
      <span className="barText">{content.contentMessageBeforeTimer}</span>
      {style.styleTimer === "flipClock" && (
        <FlipCountdown targetDate={targetDate} />
      )}
      {style.styleTimer === "plainNumbers" && (
        <PlainNumbersContdown targetDate={targetDate} />
      )}
      {style.styleTimer === "tiles" && (
        <TilesCountdown targetDate={targetDate} />
      )}
      <span className="barText">{content.contentMessageAfterTimer}</span>
      {content.contentCallToAction === "addButton" && (
        <button
          ref={buttonRef}
          className="callToAction"
          onClick={() => {
            if (preview) return;
            if (content.contentButtonOpenNewTab) {
              window.open(content.contentButtonLinkUrl, "_blank"); // open link in new tab
            } else {
              window.location.href = content.contentButtonLinkUrl; // open link in same tab
            }
          }}
          style={{
            maxWidth: "10rem",
            margin: !mobilePreview ? "auto" : "0",
          }}
        >
          {content.contentButtonText}
        </button>
      )}
      {content.contentIncludeCloseButton && (
        <button
          className="closeButton"
          style={{
            marginRight: !mobilePreview ? "0" : "1rem",
          }}
          onClick={() => {
            if (preview) return;
            setIsVisible(false);
          }}
        >
          <XIcon size={"sm"} />
        </button>
      )}
    </div>
  );
}
