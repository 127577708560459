import { Button, Table, Tag, Text, Toggle } from "@nimbus-ds/components";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { TrashIcon } from "../ui/Icons";
import { useEffect, useState } from "react";
import il8next from "../../I18n";

export default function BarTableDesktop({
  fetchedBars,
  onBarStatusChange,
  onDeleteBar,
}) {
  const [bars, setBars] = useState(fetchedBars);

  useEffect(() => {
    setBars(fetchedBars);
  }, [fetchedBars]);

  const handleToggle = async (clickedBarId) => {
    // Find the clicked bar
    const clickedBar = bars.find((bar) => bar.id === clickedBarId);
    const newStatus = clickedBar.status === "active" ? "inactive" : "active";

    // Send API request to update the bar status
    const response = await onBarStatusChange(clickedBarId, newStatus);
    // If the API call is successful, update the local state
    if (!response.success) return;
    setBars((prevBars) =>
      prevBars.map((bar) => {
        if (bar.id === clickedBarId) {
          return { ...bar, status: newStatus };
        } else {
          return { ...bar, status: "inactive" };
        }
      })
    );
  };

  return (
    <Table>
      <Table.Head>
        <Table.Cell>{il8next.t("bars:Status")}</Table.Cell>
        <Table.Cell>{il8next.t("bars:Name")}</Table.Cell>
        <Table.Cell>{il8next.t("bars:Info")}</Table.Cell>
        <Table.Cell>{il8next.t("bars:Actions")}</Table.Cell>
      </Table.Head>
      <Table.Body>
        {bars.map((bar) => (
          <Table.Row key={bar.id}>
            <Table.Cell>
              <span
                style={{
                  marginRight: "10px",
                  color: bar.status === "active" ? "green" : "red",
                }}
              >
                {bar.status === "active" ? (
                  <Tag appearance="success">
                    <Text color="primary-textLow">
                      {il8next.t("bars:Active")}
                    </Text>
                  </Tag>
                ) : (
                  <Tag appearance="warning">
                    <Text color="primary-textLow">
                      {il8next.t("bars:Paused")}
                    </Text>
                  </Tag>
                )}
              </span>
            </Table.Cell>
            <Table.Cell>
              <Link to={`bar/${bar.id}`}>{bar.name}</Link>
            </Table.Cell>
            <Table.Cell>
              {il8next.t("bars:Timer")}:{" "}
              <span
                style={{
                  textTransform: "capitalize",
                }}
              >
                {bar.timerConfiguration.timerType}
              </span>
            </Table.Cell>
            <Table.Cell>
              <span>
                <span
                  style={{
                    display: "flex",
                  }}
                >
                  <Toggle
                    name={`bar-${bar.id}`}
                    checked={bar.status === "active"}
                    onClick={() => handleToggle(bar.id)}
                  />
                  <span
                    style={{
                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      Swal.fire({
                        title: il8next.t("Are You Sure"),
                        text: il8next.t("You want be able to revert this"),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: il8next.t("Yes, delete it"),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          onDeleteBar(bar.id);
                        }
                      });
                    }}
                  >
                    <TrashIcon
                      size={"sm"}
                      style={{
                        color: "red",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </span>
              </span>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
