import { useTranslation } from "react-i18next";
import { Select } from "@nimbus-ds/components";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const savedLanguage = localStorage.getItem("i18nextLng") || "en";
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
    //refresh the page
    window.location.reload();
  };

  return (
    <div>
      <Select
        appearance="neutral"
        id="languageSelect"
        name="languageSelect"
        onChange={(e) => changeLanguage(e.target.value)}
        value={savedLanguage}
      >
        <Select.Option label="Español" value="es" />
        <Select.Option label="Portugués" value="pt" />
      </Select>
    </div>
  );
};

export default LanguageSwitcher;
