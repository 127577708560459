import { useCallback } from "react";
import axios from "./axios";
const useFetch = () => {
  const request = useCallback(async (params) => {
    let axiosResponse;
    try {
      axiosResponse = await axios.request({
        ...params,
      });
      return {
        content: axiosResponse?.data,
        statusCode: axiosResponse?.status,
      };
    } catch (error) {
      axiosResponse = error.response;
      return Promise.reject({
        message: axiosResponse?.data.message || "error",
        statusCode: axiosResponse?.status,
      });
    }
  }, []);

  return { request };
};

export default useFetch;
