import {
  Box,
  Button,
  Card,
  Icon,
  Pagination,
  Spinner,
  Text,
  Title,
} from "@nimbus-ds/components";
import { Link } from "react-router-dom";
import axios from "../utils/axios";
import Responsive from "../components/Responsive";
import BarTableDesktop from "../components/Bar/TableDestkop";
import BarsDataProvider from "./Bar/BarsDataProvider";
import { TrashIcon } from "../components/ui/Icons";
import BarDataList from "../components/Bar/DataList";
import il8next from "../I18n";

export default function MainPage() {
  return (
    <Card>
      <Card.Header>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Title as="h3">{il8next.t("Bars")}</Title>
          <Link to="new">
            <Button appearance="primary">
              {il8next.t("Create New Bar", { ns: "bars" })}
            </Button>
          </Link>
        </Box>
      </Card.Header>
      <Card.Body>
        <BarsDataProvider>
          {({ bars, onDeleteBar, onBarStatusChange, isLoading }) => {
            if (isLoading) {
              return (
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="center"
                  height="200px"
                >
                  <Spinner />
                </Box>
              );
            }
            return (
              <Responsive
                desktopContent={
                  <>
                    {bars.length === 0 ? (
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                      >
                        <Text>
                          {il8next.t("No bars created", { ns: "bars" })}
                        </Text>
                      </Box>
                    ) : (
                      <BarTableDesktop
                        onDeleteBar={onDeleteBar}
                        onBarStatusChange={onBarStatusChange}
                        fetchedBars={bars}
                      />
                    )}
                  </>
                }
                mobileContent={
                  <>
                    {bars.length === 0 ? (
                      <Box
                        alignItems="center"
                        display="flex"
                        justifyContent="center"
                      >
                        <Text>
                          {il8next.t("No bars created", { ns: "bars" })}
                        </Text>
                      </Box>
                    ) : (
                      // Show bar info on a box
                      <BarDataList
                        onDeleteBar={onDeleteBar}
                        onBarStatusChange={onBarStatusChange}
                        fetchedBars={bars}
                      />
                    )}
                  </>
                }
              />
            );
          }}
        </BarsDataProvider>
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
}
