import { createContext, useEffect, useState } from "react";
import { format, addDays } from "date-fns";
import { useTranslation } from "react-i18next";

export const BarContext = createContext();

export const BarProvider = ({ bar, children }) => {
  const { t } = useTranslation();
  const [mobilePreview, setMobilePreview] = useState(false);
  const [content, setContent] = useState({
    id: null,
    barName: t("defaults.Countdown timer bar", {
      ns: "bars",
    }),
    contentMessageBeforeTimer: t("defaults.Sale on for another", {
      ns: "bars",
    }),
    contentMessageAfterTimer: t("defaults.Save 15% on select products", {
      ns: "bars",
    }),
    contentCallToAction: "none",
    contentButtonText: t("defaults.See deals", {
      ns: "bars",
    }),
    contentButtonLinkUrl: "",
    contentButtonOpenNewTab: false,
    contentBarLinkUrl: "",
    contentBarOpenNewTab: false,
    contentIncludeCloseButton: true,
    contentDisplayPosition: 1,
  });

  const [timer, setTimer] = useState({
    id: null,
    timerType: "standard",
    timerStandardStart: "now",
    timerStandardStartDateTime: new Date(),
    timerStandardCountdownDateTime: addDays(new Date(), 7),
    timerDailyStartTime: format(new Date(), "HH:mm"),
    timerDailyEndTime: format(new Date(), "HH:mm"),
    timerDailyDays: {
      monday: true,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
    timerDailyVisitorRecurrence: false,
    timerDailyCampaignStart: "now",
    timerDailyCampaignStartDateTime: new Date(),
    timerDailyCampaignEnd: "paused",
    timerDailyCampaignEndDateTime: new Date(),
    timerIntervalCampaignStartDateTime: new Date(),
    timerIntervalCountdownLength: 48,
    timerIntervalPauseLength: 24,
    timerIntervalCampaignEnd: "paused",
    timerIntervalCampaignEndDateTime: new Date(),
    timerIntervalVisitorRecurrence: false,
    timerSessionLength: 90,
    timerSessionVisitorRecurrence: false,
    timerSessionCampaignStart: "now",
    timerSessionCampaignStartDateTime: new Date(),
    timerSessionCampaignEnd: "untilPaused",
    timerSessionCampaignEndDateTime: new Date(),
  });

  const [style, setStyle] = useState(
    bar?.style || {
      id: null,
      styleTimer: "flipClock",
      showTimerLabels: false,
      buttonBackgroundColor: "#D8AB2E",
      buttonTextColor: "#2A2C2B",
      backgroundColor: "#1E1E20",
      textColor: "#F2CA80",
      colonSeparatorColor: "#FFF3D9",
      timerDigitColor: "#FFF3D9",
      timerBackgroundColor: "#D8AB2E",
      // plainNumbers: {}, // que era esto? xd
      tiles: {
        type: "square",
        timerBackgroundGradient: false,
        timerBackgroundGradientColor: "#D8AB2E",
      },
      backgroundColorOpacity: 1,
      backgroundImageStyle: "noImage",
      backgroundImageColorGradient: {
        type: "orangeJuice",
        color1: "#FF6162",
        color2: "#FF9766",
        color3: null,
        gradientDegree: 90,
      },
      backgroundImagePatternImage: {
        type: "christmas1",
        file: null,
      },
      backgroundFittedImage: {
        type: "newYear",
        file: null,
        scrollSpeed: 0,
      },
      buttonAnimationStyle: "none",
      font: "Helvetica",
      fontSize: 16,
      barPadding: 10,
      secondsToDisplayBeforeBarDissapears: 0,
      intervalBetweenBarDisplay: 0,
      timeToFadeIn: 0,
    }
  );

  const [targeting, setTargeting] = useState(
    bar?.targeting || {
      id: null,
      targetingPage: "all",
      pageWithUrlValue: "",
      pagesWithKeywordsValue: "",
      excludedPage: "excludeNone",
      excludePageWithUrlValue: "",
      excludePageWithKeywordsValue: "",
      deviceTargeting: "displayBoth",
    }
  );

  // si es un bar existente, setear los valores del bar en los estados
  useEffect(() => {
    if (bar) {
      setContent({
        id: bar?.contentConfiguration.id,
        barName: bar?.name,
        contentMessageBeforeTimer:
          bar?.contentConfiguration.contentMessageBeforeTimer,
        contentMessageAfterTimer:
          bar?.contentConfiguration.contentMessageAfterTimer,
        contentCallToAction: bar?.contentConfiguration.contentCallToAction,
        contentButtonText: bar?.contentConfiguration.contentButtonText,
        contentButtonLinkUrl: bar?.contentConfiguration.contentButtonLinkUrl,
        contentButtonOpenNewTab:
          bar?.contentConfiguration.contentButtonOpenNewTab,
        contentBarLinkUrl: bar?.contentConfiguration.contentBarLinkUrl,
        contentBarOpenNewTab: bar?.contentConfiguration.contentBarOpenNewTab,
        contentIncludeCloseButton:
          bar?.contentConfiguration.contentIncludeCloseButton,
        contentDisplayPosition:
          bar?.contentConfiguration.contentDisplayPosition,
      });

      setTimer({
        ...bar?.timerConfiguration,
        timerStandardStartDateTime: new Date(
          bar?.timerConfiguration.timerStandardStartDateTime
        ),
        timerStandardCountdownDateTime: new Date(
          bar?.timerConfiguration.timerStandardCountdownDateTime
        ),
        timerDailyCampaignStartDateTime: new Date(
          bar?.timerConfiguration.timerDailyCampaignStartDateTime
        ),
        timerDailyCampaignEndDateTime: new Date(
          bar?.timerConfiguration.timerDailyCampaignEndDateTime
        ),
        timerIntervalCampaignStartDateTime: new Date(
          bar?.timerConfiguration.timerIntervalCampaignStartDateTime
        ),
        timerIntervalCampaignEndDateTime: new Date(
          bar?.timerConfiguration.timerIntervalCampaignEndDateTime
        ),
        timerSessionCampaignStartDateTime: new Date(
          bar?.timerConfiguration.timerSessionCampaignStartDateTime
        ),
        timerSessionCampaignEndDateTime: new Date(
          bar?.timerConfiguration.timerSessionCampaignEndDateTime
        ),
      });
      setStyle(bar?.styleConfiguration);
      setTargeting(bar?.targetingConfiguration);
    }
  }, [bar]);

  return (
    <BarContext.Provider
      value={{
        mobilePreview,
        setMobilePreview,
        content,
        setContent,
        timer,
        setTimer,
        style,
        setStyle,
        targeting,
        setTargeting,
      }}
    >
      {children}
    </BarContext.Provider>
  );
};
