import { Box, Card, Label, Text } from "@nimbus-ds/components";
import { Layout } from "@nimbus-ds/patterns";
import "./style.css";
import { useContext } from "react";
import { BarContext } from "../../../context/BarContext";
import il8next from "../../../I18n";
export default function Template() {
  const { content, style, setStyle } = useContext(BarContext);
  const basicTemplates = [
    {
      id: "boldAndClear",
      name: il8next.t("templates.Bold and Clear", { ns: "bars" }),
    },
    {
      id: "shadesOfGrey",
      name: il8next.t("templates.Shades of Grey", { ns: "bars" }),
    },
    {
      id: "brightAndElegant",
      name: il8next.t("templates.Bright and Elegant", { ns: "bars" }),
    },
    {
      id: "itsEasy",
      name: `🚚 ${il8next.t("templates.It’s Easy", { ns: "bars" })} 🚚`,
    },
    {
      id: "harvestGold",
      name: il8next.t("templates.Harvest Gold", { ns: "bars" }),
    },
    {
      id: "goodMood",
      name: `🛍 ${il8next.t("templates.Good Mood", { ns: "bars" })} 🛍`,
    },
    {
      id: "trustMe",
      name: il8next.t("templates.Trust Me", { ns: "bars" }),
    },
    {
      id: "fairyTale",
      name: il8next.t("templates.Fairy Tale", { ns: "bars" }),
    },
    {
      id: "importantThings",
      name: il8next.t("templates.Important Things", { ns: "bars" }),
    },
  ];

  const advancedTemplates = [
    {
      id: "blackDiamond",
      name: il8next.t("templates.Black Diamond", { ns: "bars" }),
    },
    {
      id: "amazonian",
      name: il8next.t("templates.Amazonian", { ns: "bars" }),
    },
    {
      id: "nikki",
      name: il8next.t("templates.Nikki", { ns: "bars" }),
    },
    {
      id: "giftSpark",
      name: il8next.t("templates.Gift Spark", { ns: "bars" }),
    },
    {
      id: "itsANewDay",
      name: il8next.t("templates.It’s a New Day", { ns: "bars" }),
    },
    {
      id: "fullSpring",
      name: il8next.t("templates.Full Spring", { ns: "bars" }),
    },
    {
      id: "summerWave",
      name: il8next.t("templates.Summer Wave", { ns: "bars" }),
    },
    {
      id: "roseMakeup",
      name: il8next.t("templates.Rose Makeup", { ns: "bars" }),
    },
    {
      id: "christmas",
      name: il8next.t("templates.Christmas", { ns: "bars" }),
    },
  ];

  const handleTemplate = (id) => {
    switch (id) {
      case "boldAndClear":
        setStyle({
          ...style,
          backgroundImageStyle: "noImage",
          styleTimer: "flipClock",
          buttonBackgroundColor: "#D8AB2E",
          buttonTextColor: "#2A2C2B",
          backgroundColor: "#1E1E20",
          textColor: "#F2CA80",
          timerDigitColor: "#FFF3D9",
          timerBackgroundColor: "#D8AB2E",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 1,
        });
        break;
      case "shadesOfGrey":
        setStyle({
          ...style,
          backgroundImageStyle: "noImage",
          styleTimer: "flipClock",
          backgroundColor: "#4C4C4C",
          buttonBackgroundColor: "#FFC229",
          buttonTextColor: "#332007",
          textColor: "#CCCCCC",
          timerDigitColor: "#E2E2E2",
          timerBackgroundColor: "#999999",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 1,
        });
        break;
      case "brightAndElegant":
        setStyle({
          ...style,
          backgroundImageStyle: "noImage",
          styleTimer: "flipClock",
          buttonBackgroundColor: "#F23E43",
          buttonTextColor: "#FFFFFF",
          backgroundColor: "#F2F2F2",
          textColor: "#354B5E",
          timerDigitColor: "#FFFFFF",
          timerBackgroundColor: "#F23E43",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 1,
        });
        break;
      case "itsEasy":
        setStyle({
          ...style,
          backgroundImageStyle: "noImage",
          styleTimer: "flipClock",
          buttonBackgroundColor: "#FF6D37",
          buttonTextColor: "#FFFFFF",
          backgroundColor: "#FFFFFF",
          textColor: "#FF6D37",
          timerDigitColor: "#FFFFFF",
          timerBackgroundColor: "#FF6D37",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 1,
        });
        break;
      case "harvestGold":
        setStyle({
          ...style,
          backgroundImageStyle: "noImage",
          styleTimer: "flipClock",
          buttonBackgroundColor: "#FFB03B",
          buttonTextColor: "#8E2800",
          backgroundColor: "#FFF0A5",
          textColor: "#B64926",
          timerDigitColor: "#FFFFFF",
          timerBackgroundColor: "#FFB03B",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 1,
        });
        break;
      case "goodMood":
        setStyle({
          ...style,
          backgroundImageStyle: "noImage",
          styleTimer: "flipClock",
          buttonBackgroundColor: "#F25B37",
          buttonTextColor: "#FFE3C9",
          backgroundColor: "#C7F9D8",
          textColor: "#3F3F3F",
          timerDigitColor: "#FFE3C9",
          timerBackgroundColor: "#F25B37",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 1,
        });
        break;
      case "trustMe":
        setStyle({
          ...style,
          backgroundImageStyle: "noImage",
          styleTimer: "flipClock",
          buttonBackgroundColor: "#FFC229",
          buttonTextColor: "#242620",
          backgroundColor: "#05AFF2",
          textColor: "#F2F2F2",
          timerDigitColor: "#F2F2F2",
          timerBackgroundColor: "#FFC229",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 1,
        });
        break;
      case "fairyTale":
        setStyle({
          ...style,
          backgroundImageStyle: "noImage",
          styleTimer: "flipClock",
          buttonBackgroundColor: "#F94D4A",
          buttonTextColor: "#FAE8EB",
          backgroundColor: "#FFD9D2",
          textColor: "#7F4D43",
          timerDigitColor: "#FAE8EB",
          timerBackgroundColor: "#F94D4A",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 1,
        });
        break;
      case "importantThings":
        setStyle({
          ...style,
          backgroundImageStyle: "noImage",
          styleTimer: "flipClock",
          buttonBackgroundColor: "#FFFFFF",
          buttonTextColor: "#E85756",
          backgroundColor: "#EB3E3E",
          textColor: "#FFF681",
          timerDigitColor: "#FFFFFF",
          timerBackgroundColor: "#95E4E8",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 1,
        });
        break;
      case "blackDiamond":
        setStyle({
          ...style,
          backgroundImageStyle: "patternImage",
          backgroundImagePatternImage: {
            type: "dark",
            file: null,
          },
          styleTimer: "flipClock",
          buttonBackgroundColor: "#FF6D37",
          buttonTextColor: "#FFFFFF",
          backgroundColor: "#1E1E20",
          textColor: "#FFFFFF",
          timerDigitColor: "#FFFFFF",
          timerBackgroundColor: "#FF6D37",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 0,
        });
        break;
      case "amazonian":
        setStyle({
          ...style,
          backgroundImageStyle: "patternImage",
          backgroundImagePatternImage: {
            type: "dust",
            file: null,
          },
          styleTimer: "flipClock",
          buttonBackgroundColor: "#FFC229",
          buttonTextColor: "#332007",
          backgroundColor: "#102033",
          textColor: "#EAD7BA",
          timerDigitColor: "#FFFFFF",
          timerBackgroundColor: "#FFC229",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 0,
        });
        break;
      case "nikki":
        setStyle({
          ...style,
          backgroundImageStyle: "patternImage",
          backgroundImagePatternImage: {
            type: "seigaiha",
            file: null,
          },
          styleTimer: "flipClock",
          buttonBackgroundColor: "#ED3E68",
          buttonTextColor: "#F2F2F2",
          backgroundColor: "#F2F2F2",
          textColor: "#6B6B6B",
          timerDigitColor: "#F2F2F2",
          timerBackgroundColor: "#ED3E68",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 0,
        });
        break;
      case "giftSpark":
        setStyle({
          ...style,
          backgroundImageStyle: "patternImage",
          backgroundImagePatternImage: {
            type: "memphis",
            file: null,
          },
          styleTimer: "flipClock",
          buttonBackgroundColor: "#FF7878",
          buttonTextColor: "#FFFFFF",
          backgroundColor: "#FFFFFF",
          textColor: "#6B6B6B",
          timerDigitColor: "#FFFFFF",
          timerBackgroundColor: "#FF7878",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 0,
        });
        break;
      case "itsANewDay":
        setStyle({
          ...style,
          backgroundImageStyle: "patternImage",
          backgroundImagePatternImage: {
            type: "yellow",
            file: null,
          },
          styleTimer: "flipClock",
          buttonBackgroundColor: "#ED5A32",
          buttonTextColor: "#FFF0A5",
          backgroundColor: "#FFF0A5",
          textColor: "#875A3C",
          timerDigitColor: "#FFFFFF",
          timerBackgroundColor: "#ED5A32",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 0,
        });
        break;
      case "fullSpring":
        setStyle({
          ...style,
          backgroundImageStyle: "patternImage",
          backgroundImagePatternImage: {
            type: "pentagon",
            file: null,
          },
          styleTimer: "flipClock",
          buttonBackgroundColor: "#FF3C47",
          buttonTextColor: "#FFFFFF",
          backgroundColor: "#C7F9D8",
          textColor: "#3F3F3F",
          timerDigitColor: "#FFFFFF",
          timerBackgroundColor: "#FF3C47",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 0,
        });
        break;
      case "summerWave":
        setStyle({
          ...style,
          backgroundImageStyle: "patternImage",
          backgroundImagePatternImage: {
            type: "waves",
            file: null,
          },
          styleTimer: "flipClock",
          buttonBackgroundColor: "#FFC229",
          buttonTextColor: "#161616",
          backgroundColor: "#05AFF2",
          textColor: "#002B38",
          timerDigitColor: "#FFFFFF",
          timerBackgroundColor: "#FFC229",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 0,
        });
        break;
      case "roseMakeup":
        setStyle({
          ...style,
          backgroundImageStyle: "patternImage",
          backgroundImagePatternImage: {
            type: "acrylic",
            file: null,
          },
          styleTimer: "flipClock",
          buttonBackgroundColor: "#3DB7B7",
          buttonTextColor: "#FFFFFF",
          backgroundColor: "#FFEBEE",
          textColor: "#E8535E",
          timerDigitColor: "#FFFFFF",
          timerBackgroundColor: "#3DB7B7",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 0,
        });
        break;
      case "christmas":
        setStyle({
          ...style,
          backgroundImageStyle: "patternImage",
          backgroundImagePatternImage: {
            type: "christmas1",
            file: null,
          },
          styleTimer: "flipClock",
          buttonBackgroundColor: "#49CC97",
          buttonTextColor: "#FFFFFF",
          backgroundColor: "#EB3E3E",
          textColor: "#FFFFFF",
          timerDigitColor: "#FFFFFF",
          timerBackgroundColor: "#49CC97",
          font: "Helvetica",
          fontSize: 16,
          backgroundColorOpacity: 0,
        });
        break;

      default:
        break;
    }
  };

  return (
    <Card>
      <Card.Header>
        <Text>Template</Text>
      </Card.Header>
      <Layout columns="2 - symmetric">
        <Layout.Section>
          {basicTemplates.map((template) => (
            <div
              key={template.id}
              className={`${template.id} templateBox`}
              onClick={() => handleTemplate(template.id)}
            >
              <Text
                fontSize="caption"
                textAlign="center"
                color="primary-interactive"
              >
                {template.name}
              </Text>
            </div>
          ))}
        </Layout.Section>
        <Layout.Section>
          {advancedTemplates.map((template) => (
            <div
              key={template.id}
              className={`${template.id} templateBox`}
              onClick={() => handleTemplate(template.id)}
            >
              <Text
                fontSize="caption"
                textAlign="center"
                color="primary-interactive"
              >
                {template.name}
              </Text>
            </div>
          ))}
        </Layout.Section>
      </Layout>
    </Card>
  );
}
