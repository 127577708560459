import il8next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
const savedLanguage = localStorage.getItem("i18nextLng") || "en";

il8next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    fallbackLng: "es",
    lng: savedLanguage || "es",
    resources: {
      es: {
        translation: {
          Bars: "Barras",
          Home: "Inicio",
          Help: "Ayuda",
          "Are You Sure": "¿Estás seguro?",
          "You want be able to revert this": "No podrás revertir esto",
          "Yes, delete it": "Sí, bórralo",
          Cancel: "Cancelar",
          Save: "Guardar",
          Yes: "Sí",
          No: "No",
          Back: "Atrás",
        },
        bars: {
          "Edit Bar": "Editar barra",
          "Create New Bar": "Crear nueva barra",
          "No bars created": "No hay barras creadas",
          Active: "Activada",
          Paused: "Pausada",
          Status: "Estado",
          Name: "Nombre",
          Info: "Información",
          Actions: "Acciones",
          Timer: "Temporizador",
          "Creating new bar": "Creando nueva barra",
          Config: "Configuración",
          "Mobile preview": "Vista móvil",
          "Desktop preview": "Vista de escritorio",
          "Switch to desktop preview": "Cambiar a vista de escritorio",
          "Switch to mobile preview": "Cambiar a vista móvil",
          templates: {
            "Bold and Clear": "Negrita y clara",
            "Shades of Grey": "Tonos de gris",
            "Bright and Elegant": "Brillante y elegante",
            "It’s Easy": "Es fácil",
            "Harvest Gold": "Oro cosechado",
            "Good Mood": "Buen humor",
            "Trust Me": "Confía en mí",
            "Fairy Tale": "Cuento de hadas",
            "Important Things": "Cosas importantes",
            "Black Diamond": "Diamante oscuro",
            Amazonian: "Amazónico",
            Nikki: "Nikki",
            "Gift Spark": "Chispa de regalo",
            "It’s a New Day": "Es un nuevo día",
            "Full Spring": "Primavera completa",
            "Summer Wave": "Ola de verano",
            "Rose Makeup": "Maquillaje de rosa",
            Christmas: "Navidad",
          },
          content: {
            "Content configuration": "Configuración de contenido",
            "Customize the content of your bar":
              "Personaliza el contenido de tu barra",
            "For your internal reference, only you will see this":
              "Para tu referencia interna, solo tú verás esto",
            "Bar name": "Nombre de la barra",
            "Message before timer": "Mensaje antes del temporizador",
            "Message after timer": "Mensaje después del temporizador",
            "Call to action": "Llamado a la acción",
            None: "Ninguno",
            "Add a button": "Agregar un botón",
            "Make the entire bar clickable":
              "Haz que toda la barra sea clickeable",
            "Button text": "Texto del botón",
            "Link url": "URL del enlace",
            "Open link in new tab when clicked":
              "Abrir enlace en nueva pestaña",
            "Include Close Button": "Incluir botón de cerrar",
            "Place an 'x' button on the bar so that users can close it manually":
              "Coloca un botón 'x' en la barra para que los usuarios puedan cerrarla manualmente",
            "Select a Display Position":
              "Selecciona una posición de visualización",
            "Top - pushes down the rest of the page":
              "Arriba - empuja hacia abajo el resto de la página",
            "Top - pushes down the rest of the page (visible while scrolling)":
              "Arriba - empuja hacia abajo el resto de la página (visible mientras se desplaza)",
            "Top - overlaps top of the page":
              "Arriba - se superpone a la parte superior de la página",
            "Top - overlaps top of the page (visible while scrolling)":
              "Arriba - se superpone a la parte superior de la página (visible mientras se desplaza)",
            "Bottom - overlaps bottom of the page (visible while scrolling)":
              "Abajo - se superpone a la parte inferior de la página (visible mientras se desplaza)",
          },
          timer: {
            "Timer configuration": "Configuración del temporizador",
            "Configure the timer to display on the bar":
              "Configura el temporizador para que se muestre en la barra",
            "Timer type": "Tipo de temporizador",
            Standard: "Estándar",
            Daily: "Diario",
            Interval: "Intervalo",
            Session: "Sesión",
            "Start displaying timer": "Comenzar a mostrar el temporizador",
            Now: "Ahora",
            "At scheduled time": "En el momento programado",
            "Countdown to": "Cuenta regresiva hasta",
            "Start time": "Hora de inicio",
            "End time": "Hora de finalización",
            "Select the days for the bar to display":
              "Selecciona los días para que se muestre la barra",
            Monday: "Lunes",
            Tuesday: "Martes",
            Wednesday: "Miércoles",
            Thursday: "Jueves",
            Friday: "Viernes",
            Saturday: "Sábado",
            Sunday: "Domingo",
            "Visitor will only see one recurring cycle":
              "El visitante solo verá un ciclo recurrente",
            "The same visitor will only see 1 cycle between the Start Time and End Time":
              "El mismo visitante solo verá 1 ciclo entre la Hora de inicio y la Hora de finalización",
            "Campaign start time": "Hora de inicio de la campaña",
            "Start displaying now": "Comenzar a mostrar ahora",
            "Start displaying at scheduled time":
              "Comenzar a mostrar en el momento programado",
            "Campaign end time": "Hora de finalización de la campaña",
            "Continue displaying until bar is paused":
              "Continuar mostrando hasta que la barra se pause",
            "Stop displaying daily timer on specific date":
              "Dejar de mostrar el temporizador diario en una fecha específica",
            "Campaign start date": "Fecha de inicio de la campaña",
            "Countdown length": "Longitud de la cuenta regresiva",
            "In hours": "En horas",
            "Pause length": "Longitud de la pausa",
            "This timer will begin displaying at":
              "Este temporizador comenzará a mostrarse a las {{timerIntervalCampaignStartDateTime}}, cuenta regresiva para: {{timerIntervalCountdownLength}} horas y llegará a 00:00, pausa por {{timerIntervalPauseLength}} horas (la barra no se mostrará), luego comenzará de nuevo.",
            "Hide recurring timer to repeat customers":
              "Ocultar temporizador recurrente a clientes repetidos",
            "If set to yes, a customer who has already seen a timer cycle will not see another one during this campaign":
              "Si se establece en sí, un cliente que ya ha visto un ciclo de temporizador no verá otro durante esta campaña",
            "Timer length (in minutes)":
              "Longitud del temporizador (en minutos)",
            "When a customer first visits the store, a timer of this length will begin":
              "Cuando un cliente visita la tienda por primera vez, comenzará un temporizador de esta longitud",
          },
          style: {
            "Style configuration": "Configuración de estilo",
            "Customize the style of your bar":
              "Personaliza el estilo de tu barra",
            "Timer Style": "Estilo del temporizador",
            "Flip clock": "Reloj de flip",
            "Plain numbers": "Números simples",
            "Square tiles": "Cuadrados",
            "Circle tiles": "Círculos",
            "None - hide timer": "Ninguno - ocultar temporizador",
            "Background color": "Color de fondo",
            "Text color": "Color del texto",
            "Timer background color": "Color de fondo del temporizador",
            "Timer digit color": "Color del dígito del temporizador",
            "Button background color": "Color de fondo del botón",
            "Button text color": "Color del texto del botón",
            "Colon separator color": "Color del separador de dos puntos",
            "Timer Background Gradient Color":
              "Color de fondo del degradado del temporizador",
            "Timer button text color":
              "Color del texto del botón del temporizador",
            "Background Color Opacity": "Opacidad del color de fondo",
            "The range is from 0 to 1. Set to 0 for fully transparent, and to 1 for solid":
              "El rango va de 0 a 1. Establece en 0 para completamente transparente, y en 1 para sólido",
            "Timer Background Gradient": "Degradado de fondo del temporizador",
            "Background Image Style": "Estilo de imagen de fondo",
            "No Image": "Sin imagen",
            "Color Gradient": "Degradado de color",
            "Pattern Image": "Imagen de patrón",
            "Fitted Image": "Imagen ajustada",
            "Select a gradient color image":
              "Selecciona una imagen de color degradado",
            "Orange Juice": "Jugo de naranja",
            "2 colors from left to right": "2 colores de izquierda a derecha",
            "Azure Pop": "Estallido de azur",
            Limeade: "Limonada",
            "Sun Raise": "Amanecer",
            "2 colors from bottom to top (0 degree)":
              "2 colores de abajo hacia arriba (0 grados)",
            "Tropical Sea": "Mar tropical",
            "2 colors from bottom left to top right (45 degree)":
              "2 colores de abajo a la izquierda a arriba a la derecha (45 grados)",
            Mauve: "Malva",
            "2 colors from bottom to top (180 degree)":
              "2 colores de abajo a arriba (180 grados)",
            Dawn: "Amanecer",
            "3 colors from bottom left to top right (45 degree)":
              "3 colores de abajo a la izquierda a arriba a la derecha (45 grados)",
            River: "Río",
            "Gray Scale": "Escala de grises",
            "3 colors from bottom left to top right (60 degree)":
              "3 colores de abajo a la izquierda a arriba a la derecha (60 grados)",
            "Gradient color 1": "Color degradado 1",
            "Gradient color 2": "Color degradado 2",
            "Gradient color 3": "Color degradado 3",
            "Gradient Degree": "Grado de degradado",
            "2 colors from top to bottom (180 degree)":
              "2 colores de arriba a abajo (180 grados)",
            "The range is from 0 to 360. Set to 0 for horizontal gradient, and to 90 for vertical gradient":
              "El rango va de 0 a 360. Establece en 0 para degradado horizontal, y en 90 para degradado vertical",
            "Select a pattern image": "Selecciona una imagen de patrón",
            Christmas: "Navidad",
            Sales: "Ventas",
            "Black Friday": "Black Friday",
            "Cyber Monday": "Cyber Monday",
            Sakura: "Sakura",
            Acrylic: "Acrílico",
            Memphis: "Memphis",
            Valentines: "Día de San Valentín",
            Seigaiha: "Seigaiha",
            Herb: "Hierba",
            "St Patrick": "San Patricio",
            Pentagon: "Pentágono",
            Yellow: "Amarillo",
            "New Year": "Año Nuevo",
            Waves: "Olas",
            Dust: "Polvo",
            Dark: "Oscuro",
            Halloween: "Halloween",
            "Or, upload your own image": "O, sube tu propia imagen",
            "Select an image": "Selecciona una imagen",
            "Lunar New Year": "Año Nuevo Lunar",
            "Thanks Giving": "Día de Acción de Gracias",
            "Click to upload or drag and drop":
              "Haz clic para subir o arrastra y suelta",
            "Scroll Speed": "Velocidad de desplazamiento",
            "Number of seconds to scroll from top to bottom. 0 means not scrolling":
              "Número de segundos para desplazarse de arriba a abajo. 0 significa que no se desplaza",
            "Button Animation Style": "Estilo de animación del botón",
            "No Anmation": "Sin animación",
            Emitting: "Emisión",
            Bounce: "Rebote",
            Flash: "Destello",
            "Rubber Band": "Banda de goma",
            Swing: "Columpio",
            Tada: "Tada",
            Wobble: "Tambalearse",
            Jello: "Gelatina",
            Flip: "Voltear",
            "Message Fonts": "Fuentes de mensaje",
            "Font Size": "Tamaño de fuente",
            "If the bar is targeted to both desktops and mobile devices, the font size will be capped on mobile devices for optimal display":
              "Si la barra está dirigida a computadoras de escritorio y dispositivos móviles, el tamaño de la fuente se limitará en dispositivos móviles para una visualización óptima",
            "Bar Padding": "Espacio de relleno de la barra",
            "Space between the text and the upper/lower borders. If the bar is targeted to both desktops and mobile devices, the font size will be capped on mobile devices for optimal display":
              "Espacio entre el texto y los bordes superior/inferior. Si la barra está dirigida a computadoras de escritorio y dispositivos móviles, el tamaño de la fuente se limitará en dispositivos móviles para una visualización óptima",
            "Interval between bar displays":
              "Intervalo entre visualizaciones de la barra",
            "Bar will only load if visiting a new page this many seconds after it last loaded":
              "La barra solo se cargará si se visita una nueva página este número de segundos después de que se cargó por última vez",
            "Seconds to display before bar disappears":
              "Segundos para mostrar antes de que la barra desaparezca",
            "Bar will not disappear if set to 0":
              "La barra no desaparecerá si se establece en 0",
            "Time to fade in": "Tiempo para desvanecerse",
            "Bar will not fade if set to 0":
              "La barra no se desvanecerá si se establece en 0",
          },
          targeting: {
            "Targeting configuration": "Configuración de segmentación",
            "Define where and when the bar will be displayed":
              "Define dónde y cuándo se mostrará la barra",
            "Page Targeting": "Segmentación de página",
            "All pages": "Todas las páginas",
            "Homepage only": "Solo página de inicio",
            "Products page only": "Solo página de productos",
            "Only on page with URL (Copy and paste the URL bekow)":
              "Solo en la página con URL (Copia y pega la URL a continuación)",
            "Input the link address above (you can copy and paste the page URL directly into the field)":
              "Ingresa la dirección del enlace anterior (puedes copiar y pegar la URL de la página directamente en el campo)",
            "Only on Pages that contain the keyword in their URLs":
              "Solo en páginas que contienen la palabra clave en sus URL",
            "Input the Keywords above. Use commas to separate if there are multiple keywords. The bar displays if any keyword is matched":
              "Ingresa las palabras clave anteriores. Usa comas para separar si hay varias palabras clave. La barra se muestra si coincide alguna palabra clave",
            "handbag, cart, shirt": "bolso, carrito, camisa",
            "Exclude from page(s)": "Excluir de la(s) página(s)",
            "Do NOT exclude any page": "NO excluir ninguna página",
            Homepage: "Página de inicio",
            "Product page": "Página de producto",
            "Only exclude Page with URL (Copy and Paste the URL below)":
              "Solo excluir la página con URL (Copia y pega la URL a continuación)",
            "Only exclude Pages that contain the keyword in their URLs":
              "Solo excluir páginas que contienen la palabra clave en sus URL",
            "Input the Keywords above. Use commas to separate if there are multiple keywords. The bar does not display if any keyword is matched":
              "Ingresa las palabras clave anteriores. Usa comas para separar si hay varias palabras clave. La barra no se muestra si coincide alguna palabra clave",
            "Device Targeting": "Segmentación de dispositivo",
            "Display on both desktop and mobile browsers (on mobile browsers, font size will be capped to achieve optimum display)":
              "Mostrar en navegadores de escritorio y móviles (en navegadores móviles, el tamaño de la fuente se limitará para lograr una visualización óptima)",
            "Display only on desktop browsers":
              "Mostrar solo en navegadores de escritorio",
            "Display only on mobile browsers (font size will not capped)":
              "Mostrar solo en navegadores móviles (el tamaño de la fuente no se limitará)",
          },
          defaults: {
            "Countdown timer bar": "Barra de temporizador de cuenta regresiva",
            "Sale on for another": "Descuento por otros",
            "Save 15% on select products":
              "Ahorra 15% en productos seleccionados",
            "See deals": "Ver ofertas",
          },
        },
      },
      pt: {
        translation: {
          Bars: "Barras",
          Home: "Início",
          Help: "Ajuda",
          "Are You Sure": "Você tem certeza?",
          "You want be able to revert this": "Você não poderá reverter isso",
          "Yes, delete it": "Sim, exclua",
          Cancel: "Cancelar",
          Save: "Salvar",
          Yes: "Sim",
          No: "Não",
          Back: "Voltar",
        },
        bars: {
          "Edit Bar": "Editar barra",
          "Create New Bar": "Criar nova barra",
          "No bars created": "Nenhuma barra criada",
          Active: "Ativada",
          Paused: "Pausada",
          Status: "Status",
          Name: "Nome",
          Info: "Informação",
          Actions: "Ações",
          Timer: "Temporizador",
          "Creating new bar": "Criando nova barra",
          Config: "Configuração",
          "Mobile preview": "Visualização móvel",
          "Desktop preview": "Visualização de desktop",
          "Switch to desktop preview": "Mudar para visualização de desktop",
          "Switch to mobile preview": "Mudar para visualização móvel",
          templates: {
            "Bold and Clear": "Negrito e Claro",
            "Shades of Grey": "Tons de Cinza",
            "Bright and Elegant": "Brilhante e Elegante",
            "It’s Easy": "É Fácil",
            "Harvest Gold": "Ouro da Colheita",
            "Good Mood": "Bom Humor",
            "Trust Me": "Confie em Mim",
            "Fairy Tale": "Conto de Fadas",
            "Important Things": "Coisas Importantes",
            "Black Diamond": "Diamante Negro",
            Amazonian: "Amazônico",
            Nikki: "Nikki",
            "Gift Spark": "Faísca de Presente",
            "It’s a New Day": "É um Novo Dia",
            "Full Spring": "Primavera Completa",
            "Summer Wave": "Onda de Verão",
            "Rose Makeup": "Maquiagem Rosa",
            Christmas: "Natal",
          },
          content: {
            "Content configuration": "Configuração de conteúdo",
            "Customize the content of your bar":
              "Personalize o conteúdo da sua barra",
            "For your internal reference, only you will see this":
              "Para sua referência interna, apenas você verá isso",
            "Bar name": "Nome da barra",
            "Message before timer": "Mensagem antes do temporizador",
            "Message after timer": "Mensagem após o temporizador",
            "Call to action": "Chamada para ação",
            None: "Nenhum",
            "Add a button": "Adicionar um botão",
            "Make the entire bar clickable": "Tornar toda a barra clicável",
            "Button text": "Texto do botão",
            "Link url": "URL do link",
            "Open link in new tab when clicked":
              "Abrir link em nova aba ao clicar",
            "Include Close Button": "Incluir botão de fechar",
            "Place an 'x' button on the bar so that users can close it manually":
              "Coloque um botão 'x' na barra para que os usuários possam fechá-la manualmente",
            "Select a Display Position": "Selecione uma posição de exibição",
            "Top - pushes down the rest of the page":
              "Topo - empurra o resto da página para baixo",
            "Top - pushes down the rest of the page (visible while scrolling)":
              "Topo - empurra o resto da página para baixo (visível ao rolar)",
            "Top - overlaps top of the page":
              "Topo - sobrepõe-se ao topo da página",
            "Top - overlaps top of the page (visible while scrolling)":
              "Topo - sobrepõe-se ao topo da página (visível ao rolar)",
            "Bottom - overlaps bottom of the page (visible while scrolling)":
              "Inferior - sobrepõe-se ao fundo da página (visível ao rolar)",
          },
          timer: {
            "Timer configuration": "Configuração do temporizador",
            "Configure the timer to display on the bar":
              "Configure o temporizador para exibição na barra",
            "Timer type": "Tipo de temporizador",
            Standard: "Padrão",
            Daily: "Diário",
            Interval: "Intervalo",
            Session: "Sessão",
            "Start displaying timer": "Iniciar exibição do temporizador",
            Now: "Agora",
            "At scheduled time": "Na hora agendada",
            "Countdown to": "Contagem regressiva para",
            "Start time": "Hora de início",
            "End time": "Hora de término",
            "Select the days for the bar to display":
              "Selecione os dias para exibir a barra",
            Monday: "Segunda-feira",
            Tuesday: "Terça-feira",
            Wednesday: "Quarta-feira",
            Thursday: "Quinta-feira",
            Friday: "Sexta-feira",
            Saturday: "Sábado",
            Sunday: "Domingo",
            "Visitor will only see one recurring cycle":
              "O visitante verá apenas um ciclo recorrente",
            "The same visitor will only see 1 cycle between the Start Time and End Time":
              "O mesmo visitante verá apenas 1 ciclo entre a Hora de início e a Hora de término",
            "Campaign start time": "Hora de início da campanha",
            "Start displaying now": "Iniciar exibição agora",
            "Start displaying at scheduled time":
              "Iniciar exibição na hora agendada",
            "Campaign end time": "Hora de término da campanha",
            "Continue displaying until bar is paused":
              "Continuar exibindo até que a barra seja pausada",
            "Stop displaying daily timer on specific date":
              "Parar de exibir temporizador diário em data específica",
            "Campaign start date": "Data de início da campanha",
            "Countdown length": "Duração da contagem regressiva",
            "In hours": "Em horas",
            "Pause length": "Duração da pausa",
            "This timer will begin displaying at":
              "Este temporizador começará a ser exibido às {{timerIntervalCampaignStartDateTime}}, contagem regressiva para: {{timerIntervalCountdownLength}} horas e chegará a 00:00, pausa por {{timerIntervalPauseLength}} horas (a barra não será exibida), depois começará novamente.",
            "Hide recurring timer to repeat customers":
              "Ocultar temporizador recorrente para clientes repetidos",
            "If set to yes, a customer who has already seen a timer cycle will not see another one during this campaign":
              "Se definido como sim, um cliente que já viu um ciclo de temporizador não verá outro durante esta campanha",
            "Timer length (in minutes)": "Duração do temporizador (em minutos)",
            "When a customer first visits the store, a timer of this length will begin":
              "Quando um cliente visita a loja pela primeira vez, um temporizador dessa duração começará",
          },
          style: {
            "Style configuration": "Configuração de estilo",
            "Customize the style of your bar":
              "Personalize o estilo da sua barra",
            "Timer Style": "Estilo do temporizador",
            "Flip clock": "Relógio flip",
            "Plain numbers": "Números simples",
            "Square tiles": "Quadrados",
            "Circle tiles": "Círculos",
            "None - hide timer": "Nenhum - ocultar temporizador",
            "Background color": "Cor de fundo",
            "Text color": "Cor do texto",
            "Timer background color": "Cor de fundo do temporizador",
            "Timer digit color": "Cor dos dígitos do temporizador",
            "Button background color": "Cor de fundo do botão",
            "Button text color": "Cor do texto do botão",
            "Colon separator color": "Cor do separador de dois pontos",
            "Timer Background Gradient Color":
              "Cor de fundo do degradado do temporizador",
            "Timer button text color": "Cor do texto do botão do temporizador",
            "Background Color Opacity": "Opacidade da cor de fundo",
            "The range is from 0 to 1. Set to 0 for fully transparent, and to 1 for solid":
              "O intervalo vai de 0 a 1. Defina 0 para totalmente transparente e 1 para sólido",
            "Timer Background Gradient": "Degradado de fundo do temporizador",
            "Background Image Style": "Estilo da imagem de fundo",
            "No Image": "Sem imagem",
            "Color Gradient": "Degradado de cor",
            "Pattern Image": "Imagem de padrão",
            "Fitted Image": "Imagem ajustada",
            "Select a gradient color image":
              "Selecione uma imagem de cor degradada",
            "Orange Juice": "Suco de Laranja",
            "2 colors from left to right": "2 cores da esquerda para a direita",
            "Azure Pop": "Estouro de Azul",
            Limeade: "Limonada",
            "Sun Raise": "Nascer do Sol",
            "2 colors from bottom to top (0 degree)":
              "2 cores de baixo para cima (0 graus)",
            "Tropical Sea": "Mar Tropical",
            "2 colors from bottom left to top right (45 degree)":
              "2 cores de baixo à esquerda para cima à direita (45 graus)",
            Mauve: "Malva",
            "2 colors from bottom to top (180 degree)":
              "2 cores de baixo para cima (180 graus)",
            Dawn: "Amanhecer",
            "3 colors from bottom left to top right (45 degree)":
              "3 cores de baixo à esquerda para cima à direita (45 graus)",
            River: "Rio",
            "Gray Scale": "Escala de cinza",
            "3 colors from bottom left to top right (60 degree)":
              "3 cores de baixo à esquerda para cima à direita (60 graus)",
            "Gradient color 1": "Cor degradada 1",
            "Gradient color 2": "Cor degradada 2",
            "Gradient color 3": "Cor degradada 3",
            "Gradient Degree": "Grau de degradado",
            "2 colors from top to bottom (180 degree)":
              "2 cores de cima para baixo (180 graus)",
            "The range is from 0 to 360. Set to 0 for horizontal gradient, and to 90 for vertical gradient":
              "O intervalo vai de 0 a 360. Defina 0 para degradado horizontal e 90 para degradado vertical",
            "Select a pattern image": "Selecione uma imagem de padrão",
            Christmas: "Natal",
            Sales: "Promoções",
            "Black Friday": "Black Friday",
            "Cyber Monday": "Cyber Monday",
            Sakura: "Sakura",
            Acrylic: "Acrílico",
            Memphis: "Memphis",
            Valentines: "Dia dos Namorados",
            Seigaiha: "Seigaiha",
            Herb: "Erva",
            "St Patrick": "São Patrício",
            Pentagon: "Pentágono",
            Yellow: "Amarelo",
            "New Year": "Ano Novo",
            Waves: "Ondas",
            Dust: "Poeira",
            Dark: "Escuro",
            Halloween: "Halloween",
            "Or, upload your own image": "Ou, carregue sua própria imagem",
            "Select an image": "Selecione uma imagem",
            "Lunar New Year": "Ano Novo Lunar",
            "Thanks Giving": "Dia de Ação de Graças",
            "Click to upload or drag and drop":
              "Clique para carregar ou arraste e solte",
            "Scroll Speed": "Velocidade de rolagem",
            "Number of seconds to scroll from top to bottom. 0 means not scrolling":
              "Número de segundos para rolar de cima para baixo. 0 significa que não rola",
            "Button Animation Style": "Estilo de animação do botão",
            "No Anmation": "Sem animação",
            Emitting: "Emissão",
            Bounce: "Quicar",
            Flash: "Piscada",
            "Rubber Band": "Elástico",
            Swing: "Balanço",
            Tada: "Tada",
            Wobble: "Cambalear",
            Jello: "Gelatina",
            Flip: "Virar",
            "Message Fonts": "Fontes de mensagem",
            "Font Size": "Tamanho da fonte",
            "If the bar is targeted to both desktops and mobile devices, the font size will be capped on mobile devices for optimal display":
              "Se a barra for direcionada tanto para computadores quanto para dispositivos móveis, o tamanho da fonte será limitado em dispositivos móveis para uma exibição ideal",
            "Bar Padding": "Espaçamento da barra",
            "Space between the text and the upper/lower borders. If the bar is targeted to both desktops and mobile devices, the font size will be capped on mobile devices for optimal display":
              "Espaço entre o texto e as bordas superior/inferior. Se a barra for direcionada tanto para computadores quanto para dispositivos móveis, o tamanho da fonte será limitado em dispositivos móveis para uma exibição ideal",
            "Interval between bar displays":
              "Intervalo entre exibições da barra",
            "Bar will only load if visiting a new page this many seconds after it last loaded":
              "A barra só será carregada se visitar uma nova página tantos segundos depois que foi carregada pela última vez",
            "Seconds to display before bar disappears":
              "Segundos para exibir antes que a barra desapareça",
            "Bar will not disappear if set to 0":
              "A barra não desaparecerá se estiver definida como 0",
            "Time to fade in": "Tempo para desvanecer",
            "Bar will not fade if set to 0":
              "A barra não desaparecerá se estiver definida como 0",
          },
          targeting: {
            "Targeting configuration": "Configuração de segmentação",
            "Define where and when the bar will be displayed":
              "Defina onde e quando a barra será exibida",
            "Page Targeting": "Segmentação de página",
            "All pages": "Todas as páginas",
            "Homepage only": "Apenas na página inicial",
            "Products page only": "Apenas na página de produtos",
            "Only on page with URL (Copy and paste the URL below)":
              "Apenas na página com URL (Copie e cole o URL abaixo)",
            "Input the link address above (you can copy and paste the page URL directly into the field)":
              "Insira o endereço do link acima (você pode copiar e colar o URL da página diretamente no campo)",
            "Only on Pages that contain the keyword in their URLs":
              "Apenas em páginas que contêm a palavra-chave em seus URLs",
            "Input the Keywords above. Use commas to separate if there are multiple keywords. The bar displays if any keyword is matched":
              "Insira as palavras-chave acima. Use vírgulas para separar se houver várias palavras-chave. A barra será exibida se qualquer palavra-chave corresponder",
            "handbag, cart, shirt": "bolsa, carrinho, camisa",
            "Exclude from page(s)": "Excluir da(s) página(s)",
            "Do NOT exclude any page": "NÃO excluir nenhuma página",
            Homepage: "Página inicial",
            "Product page": "Página de produto",
            "Only exclude Page with URL (Copy and Paste the URL below)":
              "Apenas excluir página com URL (Copie e cole o URL abaixo)",
            "Only exclude Pages that contain the keyword in their URLs":
              "Apenas excluir páginas que contêm a palavra-chave em seus URLs",
            "Input the Keywords above. Use commas to separate if there are multiple keywords. The bar does not display if any keyword is matched":
              "Insira as palavras-chave acima. Use vírgulas para separar se houver várias palavras-chave. A barra não será exibida se qualquer palavra-chave corresponder",
            "Device Targeting": "Segmentação de dispositivo",
            "Display on both desktop and mobile browsers (on mobile browsers, font size will be capped to achieve optimum display)":
              "Exibir em navegadores desktop e móveis (em navegadores móveis, o tamanho da fonte será limitado para obter exibição ideal)",
            "Display only on desktop browsers":
              "Exibir apenas em navegadores desktop",
            "Display only on mobile browsers (font size will not capped)":
              "Exibir apenas em navegadores móveis (o tamanho da fonte não será limitado)",
          },
          defaults: {
            "Countdown timer bar":
              "Barra de temporizador de contagem regressiva",
            "Sale on for another": "Venda por mais",
            "Save 15% on select products":
              "Economize 15% em produtos selecionados",
            "See deals": "Ver ofertas",
          },
        },
      },
    },
  });

export default il8next;
