import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Title, useToast } from "@nimbus-ds/components";
import { BarProvider } from "../context/BarContext";
import BarsDataProvider from "./Bar/BarsDataProvider";
import CreateOrUpdateBar from "../components/Bar/CreateOrUpdateBar";
import useFetch from "../utils/useFetch";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@nimbus-ds/components";
import il8next from "../I18n";

export default function NewBarPage() {
  const { request } = useFetch();
  const { addToast } = useToast();
  const params = useParams();
  const barId = params.barId;
  const [bar, setBar] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchBar = async () => {
      setIsLoading(true);
      request({
        url: `/bars/${barId}`,
        method: "GET",
      })
        .then((response) => {
          if (response.content === "") {
            navigate("/not-found");
          }
          setBar(response.content);
          setIsLoading(false);
        })
        .catch((error) => {
          addToast({
            type: "danger",
            text: "Error fetching bar",
            duration: 4000,
            id: "error-get-bar",
          });
          setIsLoading(false);
        });
    };
    fetchBar();
  }, [barId]);

  return (
    <BarProvider bar={bar}>
      {isLoading ? (
        <span
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        >
          <Spinner />
        </span>
      ) : (
        <BarsDataProvider>
          {({ onAddBar, errors, setErrors }) => (
            <>
              <Title as="h3">
                {il8next.t("Edit Bar", {
                  ns: "bars",
                })}
              </Title>

              <CreateOrUpdateBar
                bar={bar}
                onAddBar={onAddBar}
                errors={errors}
                setErrors={setErrors}
              />
            </>
          )}
        </BarsDataProvider>
      )}
    </BarProvider>
  );
}
