import {
  Accordion,
  Box,
  Card,
  Label,
  Radio,
  Text,
} from "@nimbus-ds/components";
import { FormField } from "@nimbus-ds/patterns";
import { useContext, useEffect, useState } from "react";
import { BarContext } from "../../../../context/BarContext";
import { ErrorIcon } from "../../../ui/Icons";
import il8next from "../../../../I18n";

export default function Targeting({ errors }) {
  const { targeting, setTargeting } = useContext(BarContext);
  const [localErrors, setLocalErrors] = useState({});
  // Update local errors when errors change
  useEffect(() => {
    if (errors && errors.error) {
      const newErrors = errors?.error?.reduce((acc, err) => {
        if (err.path[0] === "targeting") {
          acc[err.path[1]] = err.message;
        }
        return acc;
      }, {});
      setLocalErrors(newErrors);
    } else {
      setLocalErrors({});
    }
  }, [errors]);
  return (
    <Accordion.Item index="3">
      <Accordion.Header
        title={il8next.t("targeting.Targeting configuration", { ns: "bars" })}
        icon={
          Object.keys(localErrors).length > 0 ? (
            <ErrorIcon
              style={{
                color: "red",
              }}
            />
          ) : null
        }
        subtitle={il8next.t(
          "targeting.Define where and when the bar will be displayed",
          {
            ns: "bars",
          }
        )}
      />
      <Accordion.Body>
        <Box borderWidth="1" padding="2" width="100%">
          <Card>
            <Box padding="1">
              <Label htmlFor="targetingPage">
                {il8next.t("targeting.Page Targeting", { ns: "bars" })}
              </Label>
              <Radio
                id="all"
                name="targetingPage"
                label={il8next.t("targeting.All pages", { ns: "bars" })}
                checked={targeting.targetingPage === "all"}
                onChange={(e) => {
                  setTargeting({ ...targeting, targetingPage: "all" });
                }}
              />
              <Radio
                id="onlyHome"
                name="targetingPage"
                label={il8next.t("targeting.Homepage only", { ns: "bars" })}
                checked={targeting.targetingPage === "onlyHome"}
                onChange={(e) => {
                  setTargeting({ ...targeting, targetingPage: "onlyHome" });
                }}
              />
              <Radio
                id="productsOnly"
                name="targetingPage"
                label={il8next.t("targeting.Products page only", {
                  ns: "bars",
                })}
                checked={targeting.targetingPage === "productsOnly"}
                onChange={(e) => {
                  setTargeting({
                    ...targeting,
                    targetingPage: "productsOnly",
                  });
                }}
              />
              <Radio
                id="pageWithUrl"
                name="targetingPage"
                label={il8next.t(
                  "targeting.Only on page with URL (Copy and paste the URL bekow)",
                  { ns: "bars" }
                )}
                checked={targeting.targetingPage === "pageWithUrl"}
                onChange={(e) => {
                  setTargeting({ ...targeting, targetingPage: "pageWithUrl" });
                }}
              />
              {targeting.targetingPage === "pageWithUrl" && (
                <Box padding="3-5">
                  <FormField.Input
                    helpText={
                      localErrors.pageWithUrlValue
                        ? localErrors.pageWithUrlValue
                        : il8next.t(
                            "targeting.Input the link address above (you can copy and paste the page URL directly into the field)",
                            {
                              ns: "bars",
                            }
                          )
                    }
                    showHelpText={true}
                    appearance={
                      localErrors.pageWithUrlValue ? "danger" : "neutral"
                    }
                    id="pageWithUrlValue"
                    value={targeting.pageWithUrlValue}
                    onChange={(e) => {
                      setTargeting({
                        ...targeting,
                        pageWithUrlValue: e.target.value,
                      });
                    }}
                  />
                </Box>
              )}
              <Radio
                id="pagesWithKeywords"
                name="targetingPage"
                label={il8next.t(
                  "targeting.Only on Pages that contain the keyword in their URLs",
                  {
                    ns: "bars",
                  }
                )}
                checked={targeting.targetingPage === "pagesWithKeywords"}
                onChange={(e) => {
                  setTargeting({
                    ...targeting,
                    targetingPage: "pagesWithKeywords",
                  });
                }}
              />
              {/* Only show if targetingPage is = pagesWithKeywords */}
              {targeting.targetingPage === "pagesWithKeywords" && (
                <Box padding="3-5">
                  <FormField.Input
                    helpText={
                      localErrors.pagesWithKeywordsValue
                        ? localErrors.pagesWithKeywordsValue
                        : il8next.t(
                            "targeting.Input the Keywords above. Use commas to separate if there are multiple keywords. The bar displays if any keyword is matched",
                            { ns: "bars" }
                          )
                    }
                    appearance={
                      localErrors.pagesWithKeywordsValue ? "danger" : "neutral"
                    }
                    showHelpText={true}
                    id="pagesWithKeywordsValue"
                    placeholder={il8next.t("targeting.handbag, cart, shirt", {
                      ns: "bars",
                    })}
                    value={targeting.pagesWithKeywordsValue}
                    onChange={(e) => {
                      setTargeting({
                        ...targeting,
                        pagesWithKeywordsValue: e.target.value,
                      });
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box padding="1">
              <Label htmlFor="excludedPage">
                {il8next.t("targeting.Exclude from page(s)", { ns: "bars" })}
              </Label>
              <Radio
                id="excludeNone"
                name="excludedPage"
                label={il8next.t("targeting.Do NOT exclude any page", {
                  ns: "bars",
                })}
                checked={targeting.excludedPage === "excludeNone"}
                onChange={(e) => {
                  setTargeting({ ...targeting, excludedPage: "excludeNone" });
                }}
              />
              <Radio
                id="excludeHome"
                name="excludedPage"
                label={il8next.t("targeting.Homepage", { ns: "bars" })}
                checked={targeting.excludedPage === "excludeHome"}
                onChange={(e) => {
                  setTargeting({ ...targeting, excludedPage: "excludeHome" });
                }}
              />
              <Radio
                id="excludeProducts"
                name="excludedPage"
                label={il8next.t("targeting.Product page", { ns: "bars" })}
                checked={targeting.excludedPage === "excludeProducts"}
                onChange={(e) => {
                  setTargeting({
                    ...targeting,
                    excludedPage: "excludeProducts",
                  });
                }}
              />
              <Radio
                id="excludePageWithUrl"
                name="excludedPage"
                label={il8next.t(
                  "targeting.Only exclude Page with URL (Copy and Paste the URL below)",
                  {
                    ns: "bars",
                  }
                )}
                checked={targeting.excludedPage === "excludePageWithUrl"}
                onChange={(e) => {
                  setTargeting({
                    ...targeting,
                    excludedPage: "excludePageWithUrl",
                  });
                }}
              />
              {/* Only show if excludedPage is = excludePageWithUrl */}
              {targeting.excludedPage === "excludePageWithUrl" && (
                <Box padding="3-5">
                  <FormField.Input
                    helpText={
                      localErrors.excludePageWithUrlValue
                        ? localErrors.excludePageWithUrlValue
                        : il8next.t(
                            "targeting.Input the link address above (you can copy and paste the page URL directly into the field)",
                            { ns: "bars" }
                          )
                    }
                    appearance={
                      localErrors.excludePageWithUrlValue ? "danger" : "neutral"
                    }
                    showHelpText={true}
                    id="excludePageWithUrlValue"
                    value={targeting.excludePageWithUrlValue}
                    onChange={(e) => {
                      setTargeting({
                        ...targeting,
                        excludePageWithUrlValue: e.target.value,
                      });
                    }}
                  />
                </Box>
              )}
              <Radio
                id="excludePageWithKeywords"
                name="excludedPage"
                label={il8next.t(
                  "targeting.Only exclude Pages that contain the keyword in their URLs",
                  {
                    ns: "bars",
                  }
                )}
                checked={targeting.excludedPage === "excludePageWithKeywords"}
                onChange={(e) => {
                  setTargeting({
                    ...targeting,
                    excludedPage: "excludePageWithKeywords",
                  });
                }}
              />
              {/* Only show if excludedPage is = excludePageWithKeywords */}
              {targeting.excludedPage === "excludePageWithKeywords" && (
                <Box padding="3-5">
                  <FormField.Input
                    helpText={
                      localErrors.excludePageWithKeywordsValue
                        ? localErrors.excludePageWithKeywordsValue
                        : il8next.t(
                            "targeting.Input the Keywords above. Use commas to separate if there are multiple keywords. The bar does not display if any keyword is matched",
                            { ns: "bars" }
                          )
                    }
                    appearance={
                      localErrors.excludePageWithKeywordsValue
                        ? "danger"
                        : "neutral"
                    }
                    showHelpText={true}
                    placeholder="handbag, cart, shirt"
                    id="excludePageWithKeywordsValue"
                    value={targeting.excludePageWithKeywordsValue}
                    onChange={(e) => {
                      setTargeting({
                        ...targeting,
                        excludePageWithKeywordsValue: e.target.value,
                      });
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box padding="1">
              <Label htmlFor="excludedPage">
                {il8next.t("targeting.Device Targeting", { ns: "bars" })}
              </Label>
              <Radio
                id="displayBoth"
                name="deviceTargeting"
                label={il8next.t(
                  "targeting.Display on both desktop and mobile browsers (on mobile browsers, font size will be capped to achieve optimum display)",
                  {
                    ns: "bars",
                  }
                )}
                checked={targeting.deviceTargeting === "displayBoth"}
                onChange={(e) => {
                  setTargeting({
                    ...targeting,
                    deviceTargeting: "displayBoth",
                  });
                }}
              />
              <Radio
                id="displayOnlyDesktop"
                name="deviceTargeting"
                label={il8next.t("targeting.Display only on desktop browsers", {
                  ns: "bars",
                })}
                checked={targeting.deviceTargeting === "displayOnlyDesktop"}
                onChange={(e) => {
                  setTargeting({
                    ...targeting,
                    deviceTargeting: "displayOnlyDesktop",
                  });
                }}
              />
              <Radio
                id="displayOnlyMobile"
                name="deviceTargeting"
                label={il8next.t(
                  "targeting.Display only on mobile browsers (font size will not capped)",
                  {
                    ns: "bars",
                  }
                )}
                checked={targeting.deviceTargeting === "displayOnlyMobile"}
                onChange={(e) => {
                  setTargeting({
                    ...targeting,
                    deviceTargeting: "displayOnlyMobile",
                  });
                }}
              />
            </Box>
          </Card>
        </Box>
      </Accordion.Body>
    </Accordion.Item>
  );
}
