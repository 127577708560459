import React from "react";
import { Title } from "@nimbus-ds/components";
import CreateOrUpdateBar from "../../components/Bar/CreateOrUpdateBar";
import { BarProvider } from "../../context/BarContext";
import BarsDataProvider from "./BarsDataProvider";
import il8next from "../../I18n";

export default function NewBarPage() {
  return (
    <BarProvider bar={null}>
      <BarsDataProvider>
        {({ onAddBar, errors, setErrors }) => (
          <>
            <Title as="h3">
              {il8next.t("Creating new bar", { ns: "bars" })}
            </Title>
            <CreateOrUpdateBar
              onAddBar={onAddBar}
              errors={errors}
              setErrors={setErrors}
            />
          </>
        )}
      </BarsDataProvider>
    </BarProvider>
  );
}
