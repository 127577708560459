import React, { useState } from "react";
import { Calendar } from "@nimbus-ds/patterns";
import { Popover, Input, Icon } from "@nimbus-ds/components";
import { CalendarIcon } from "@nimbus-ds/icons";
import { toZonedTime, format } from "date-fns-tz";

const DatePicker = ({ defaultDate, onDateChange }) => {
  const [selectedDate, setSelectedDate] = useState(defaultDate);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onDateChange(date);
  };

  // format date to local timezone
  const formatDate = (date) => {
    const zonedDate = toZonedTime(
      date,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    return format(zonedDate, "dd-MM-yyyy");
  };

  const calendar = (
    <Calendar
      mode="single"
      showOutsideDays
      selected={selectedDate}
      onSelect={(date) => handleDateChange(date)}
      hideBorder
      fullWidthDays
      containerProps={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        maxHeight: "400px",
      }}
    />
  );

  return (
    <Popover content={calendar} padding="none" arrow={false}>
      <Input
        readOnly
        value={selectedDate && formatDate(selectedDate, "dd-MM-yyyy")}
        placeholder="Select a date"
        append={<Icon color="neutral-textLow" source={<CalendarIcon />} />}
        appendPosition="end"
      />
    </Popover>
  );
};

export default DatePicker;
