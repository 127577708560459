import React, { useEffect, useRef } from "react";

const FlipDigit = ({ digit }) => {
  const digitRef = useRef(null);

  useEffect(() => {
    if (digitRef.current) {
      digitRef.current.classList.remove("flip");
      void digitRef.current.offsetWidth; // Trigger reflow to restart the animation
      digitRef.current.classList.add("flip");
    }
  }, [digit]);

  return (
    <div className="flip-digit" ref={digitRef}>
      {digit}
    </div>
  );
};

export default FlipDigit;
