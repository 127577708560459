import { Input } from "@nimbus-ds/components";
import React, { useEffect, useState } from "react";

const ColorPickerInput = ({
  defaultColor,
  onColorChange,
  disabled = false,
  hasError,
}) => {
  const [color, setColor] = useState(defaultColor || "#000000");
  useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor]);

  const handleColorChange = (e) => {
    setColor(e.target.value);
    onColorChange(e.target.value);
  };

  const handleInputChange = (e) => {
    const hexColor = e.target.value;
    if (/^#[0-9A-F]{6}$/i.test(hexColor)) {
      setColor(hexColor);
      onColorChange(hexColor);
    } else {
      setColor(`#${hexColor.slice(1, 7)}`);
      onColorChange(`#${hexColor.slice(1, 7)}`);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <input
        disabled={disabled}
        type="color"
        value={color}
        onChange={handleColorChange}
        style={{
          appearance: "none",
          border: "none",
          width: "40px",
          height: "40px",
          padding: 0,
          margin: 0,
          cursor: "pointer",
        }}
      />
      <Input
        disabled={disabled}
        type="text"
        value={color}
        onChange={handleInputChange}
        maxLength={7}
        appearance={hasError ? "danger" : "neutral"}
      />
    </div>
  );
};

export default ColorPickerInput;
