import { useContext, useEffect, useState } from "react";
import { useToast } from "@nimbus-ds/components";
import useFetch from "../../utils/useFetch";
import { useNavigate } from "react-router-dom";

const BarsDataProvider = ({ children }) => {
  const { addToast } = useToast();
  const { request } = useFetch();
  const [bars, setBars] = useState([]);
  const [errors, setErrors] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    onGetBars();
  }, []);

  const onGetBars = () => {
    setIsLoading(true);
    request({
      url: `/bars`,
      method: "GET",
    })
      .then((response) => {
        setBars(response.content);
      })
      .catch((error) => {
        addToast({
          type: "danger",
          text: error.message.description ?? error.message,
          duration: 4000,
          id: "error-bars",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDeleteBar = (barId) => {
    setIsLoading(true);
    request({
      url: `/bars/${barId}`,
      method: "DELETE",
    })
      .then((data) => {
        const result = data.content;
        if (result.error) {
          if (typeof result.error === "string") {
            addToast({
              type: "danger",
              text: result.error,
              duration: 4000,
              id: "create-bar",
            });
            return;
          }
        }
        onGetBars();
        addToast({
          type: "success",
          text: "Bar deleted successfully",
          duration: 4000,
          id: "delete-bar",
        });
      })
      .catch((error) => {
        addToast({
          type: "danger",
          text: "Error deleting bar",
          duration: 4000,
          id: "error-delete-bar",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onAddBar = (data) => {
    setIsLoading(true);
    // Create a new FormData object
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));

    // if pattern image we add the file to the form data
    if (
      data.style.backgroundImagePatternImage &&
      data.style.backgroundImagePatternImage.file instanceof File
    ) {
      formData.append(
        "customPatternImage",
        data.style.backgroundImagePatternImage.file,
        data.style.backgroundImagePatternImage.file.name
      );
    }

    // if fitted image we add the file to the form data
    if (
      data.style.backgroundFittedImage &&
      data.style.backgroundFittedImage.file instanceof File
    ) {
      formData.append(
        "customFittedImage",
        data.style.backgroundFittedImage.file,
        data.style.backgroundFittedImage.file.name
      );
    }

    request({
      url: `/bars`,
      method: "POST",
      data: formData,
    })
      .then((data) => {
        const result = data.content;
        if (result.error) {
          if (typeof result.error === "string") {
            addToast({
              type: "danger",
              text: result.error,
              duration: 4000,
              id: "create-bar",
            });
            return;
          }
          setErrors(result);
          addToast({
            type: "danger",
            text: result?.error[0].message || "Error creating bar",
            duration: 4000,
            id: "create-bar",
          });
          return;
        }
        addToast({
          type: "success",
          text: "Bar created successfully",
          duration: 4000,
          id: "create-bar",
        });
        onGetBars();
        navigate(`/`);
      })
      .catch((error) => {
        console.error(error);
        addToast({
          type: "danger",
          text: "Inernal server error",
          duration: 4000,
          id: "error-create-bar",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onBarStatusChange = async (barId, status) => {
    try {
      const response = await request({
        url: `/bars/${barId}/change-status`,
        method: "PUT",
        data: { status },
      });
      if (response.content.error) {
        addToast({
          type: "danger",
          text: response.content.error,
          duration: 4000,
          id: "update-bar-status",
        });
        return;
      }
      addToast({
        type: "success",
        text: "Bar status updated successfully",
        duration: 4000,
        id: "update-bar-status",
      });
      return response.content;
    } catch (error) {
      addToast({
        type: "danger",
        text: "Error updating bar status",
        duration: 4000,
        id: "error-update-bar-status",
      });
    }
  };

  return children({
    bars,
    onAddBar,
    onDeleteBar,
    errors,
    setErrors,
    isLoading,
    onBarStatusChange,
  });
};

export default BarsDataProvider;
