import { ThemeProvider } from "@nimbus-ds/styles";
import {
  useState,
  createContext,
  useCallback,
  useMemo,
  useEffect,
  ReactNode,
} from "react";

export const DarkModeContext = createContext(null);

export const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const [mounted, setMounted] = useState(false);

  const toggleDarkMode = useCallback(
    () => setDarkMode((prevState) => !prevState),
    [setDarkMode]
  );

  const contextValue = useMemo(
    () => ({ darkMode, toggleDarkMode }),
    [darkMode, toggleDarkMode]
  );

  useEffect(() => {
    setMounted(true);
    const storageValue = localStorage.getItem("darkMode");
    if (storageValue) {
      setDarkMode(JSON.parse(storageValue));
    } else {
      setDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
    }
  }, []);

  useEffect(() => {
    if (mounted) {
      localStorage.setItem("darkMode", JSON.stringify(darkMode));
    }
  }, [darkMode, mounted]);

  return (
    <DarkModeContext.Provider value={contextValue}>
      {/* The admin currently doesn't support dark mode. When it does, replace
      `base` value with `currentTheme` to provide dark mode support. */}
      <ThemeProvider theme="base">{children}</ThemeProvider>
    </DarkModeContext.Provider>
  );
};

export default DarkModeProvider;
