import React, { useState, useEffect, useContext } from "react";
import { BarContext } from "../../../../../context/BarContext";
import "./style.css";
const Countdown = ({ targetDate }) => {
  const { style } = useContext(BarContext);

  // Define styles for square and circular tiles
  const squareStyle = {
    backgroundColor: "linear-gradient(to bottom, #D8AB2E, #D8AB2E)",
    color: "#FFF3D9",
    borderRadius: "15%",
    transform: "translate3d(0, 0, 0)",
    padding: "0.2rem",
    display: "inline-block",
    width: "30px",
    height: "30px",
  };

  const circleStyle = {
    ...squareStyle,
    borderRadius: "50%",
  };

  // Use a single state for digit style
  const [digitStyle, setDigitStyle] = useState(
    style.tiles.type === "square" ? squareStyle : circleStyle
  );

  const formatTime = (time) => {
    return time.toString().padStart(2, "0");
  };

  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (style.tiles.timerBackgroundGradient) {
      document.documentElement.style.setProperty(
        "--timer-background-color",
        `linear-gradient(to bottom, ${style.timerBackgroundColor}, ${style.tiles.timerBackgroundGradientColor})`
      );
    } else {
      document.documentElement.style.setProperty(
        "--timer-background-color",
        style.timerBackgroundColor
      );
    }
    setDigitStyle(style.tiles.type === "square" ? squareStyle : circleStyle);
  }, [style]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="">
        <span className="tile-digit" style={digitStyle}>
          <span>{formatTime(timeLeft.days)}</span>
        </span>
        <span className="colon">:</span>
        <span className="tile-digit" style={digitStyle}>
          <span> {formatTime(timeLeft.hours)}</span>
        </span>
        <span className="colon">:</span>
        <span className="tile-digit" style={digitStyle}>
          <span>{formatTime(timeLeft.minutes)}</span>
        </span>
        <span className="colon">:</span>
        <span className="tile-digit" style={digitStyle}>
          <span>{formatTime(timeLeft.seconds)}</span>
        </span>
      </div>
    </div>
  );
};

export default Countdown;
